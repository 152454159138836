export default async function({ app, route }) {
  // check if storeList is available on Vuex store
  // if not, it means that the page is refreshed by user, fetch it and set the vuex store id, which is used for all request
  // also fetch the store detail and store to vuex
  if (app.store.state.storeList.length === 0 && route.name !== "auth-login") {
    let response = null;
    try {
      response = await app.$apiFetch.stores.getAllStoreList();
    } catch (err) {
      return;
    }

    app.store.commit("setStoreList", response.data);
    const storeListFirstStoreID = response.data[0].id;
    const localStorageSelectedStoreID = localStorage.getItem("selectedStoreID");
    // selected store id is store in localStorage.
    // if both localStorage and vuex store id is empty, use the first store available on store list
    if (
      app.store.state.storeID === null &&
      localStorageSelectedStoreID === null
    ) {
      app.store.commit("setStoreID", storeListFirstStoreID);
      response = await app.$apiFetch.stores.getStoreDetail(
        storeListFirstStoreID
      );
      app.store.commit("setSelectedStoreDetail", response.data);
    }
    // if selected store id is available in localStorage, use it directly
    if (
      app.store.state.storeID === null &&
      localStorageSelectedStoreID !== null
    ) {
      app.store.commit("setStoreID", localStorageSelectedStoreID);
      try {
        response = await app.$apiFetch.stores.getStoreDetail(
          localStorageSelectedStoreID
        );
        app.store.commit("setSelectedStoreDetail", response.data);
      } catch (err) {
        // if cannot get store detail, it means that user does not have access to the store stored in the localstorage
        // use the store list first store id instead, and refresh the localstorage
        app.store.commit("setStoreID", storeListFirstStoreID);
        response = await app.$apiFetch.stores.getStoreDetail(
          storeListFirstStoreID
        );
        app.store.commit("setSelectedStoreDetail", response.data);
      }
    }

    // set i18n locale by default locale from store details
    const { locales, default_locale_id } = app.store.state.selectedStoreDetail;
    if (Array.isArray(locales) && locales.length > 0) {
      // find default locale
      const defaultLocale = locales.find(locale => {
        return locale.id === default_locale_id;
      });
      app.i18n.locale = defaultLocale.code;
    }
  }
}
