import { createHelpers } from "vuex-map-fields";

const { getProductField, updateProductField } = createHelpers({
  getterType: "getProductField",
  mutationType: "updateProductField"
});
const defaultOption = {
  option_values: [],
  manage_stock: true,
  translations: []
};

export const state = () => initialState();

function initialState() {
  return {
    product: {
      vendor: [],
      enabled: true,
      product_options: [],
      categories: [],
      children: [],
      max_saleable_qty: null,
      min_saleable_qty: null,
      hot_recommend: false,
      sort_order: 0,
      product_stock: {
        name: "",
        sku: "",
        remain_stock: 0
      },
      original_unit_price: null,
      discount_unit_price: 0,
      available_for_sale: true,
      unit_cost: 0,
      additional_information: [],
      translations: [],
      product_images: [],
      cost_currency_code: "",
      days_required_before_service_start_date_for_reservation: null,
      pricing_model: 0
    },
    tempChildren: [],
    with_child: false,
    available_until_for_date: null,
    available_until_for_timeslot: null,
    defaultOption,
    optionsBeforeEdit: [],
    childrenBeforeEdit: [],
    currentPricingModel: 0,
    mode: "create"
  };
}

export const mutations = {
  updateProductField,
  updateProduct(state, payload) {
    state.product = {
      ...state.product,
      ...payload
    };
  },
  uploadImage(state, payload) {
    state.product.product_images.push(payload);
  },
  removeImage(state, index) {
    state.product.product_images.splice(index, 1);
  },
  updateTranslations(state, payload) {
    state.product.translations.push({
      locale: "zh_HK",
      product_name: payload
    });
  },
  /**
   * @param state
   * @param {Array} payload
   */
  updateProductOptions(state, payload) {
    state.product.product_options = payload;
  },
  updateDefaultOptionTranslations(state, payload) {
    state.defaultOption.translations = payload;
  },
  addOption(state, payload) {
    state.product.product_options.push(payload);
  },
  deleteOption(state, index) {
    // state.product.product_options.splice(timeSlotIndex, 1)
    const newData = _.cloneDeep(state.product.product_options);
    newData.splice(index, 1);
    state.product.product_options = newData;
  },
  backUpOptions(state) {
    state.optionsBeforeEdit = _.cloneDeep(state.product.product_options);
  },
  backUpChildren(state) {
    state.childrenBeforeEdit = _.cloneDeep(state.product.children);
  },
  updateChildren(state, payload) {
    state.product.children = _.cloneDeep(payload);
  },
  restoreBackupOptions(state) {
    state.product.product_options = _.cloneDeep(state.optionsBeforeEdit);
  },
  restoreBackupChildren(state) {
    state.product.children = _.cloneDeep(state.childrenBeforeEdit);
  },
  updateCategories(state, payload) {
    state.product.categories = [];
    state.product.categories = payload;
  },
  updateDefaultProductOptionName(state, payload) {
    state.product.product_options[payload.index].translations[0].option_name =
      payload.option_name;
  },
  updateProductOptionValues(state, payload) {
    state.product.product_options[payload.index].option_values = payload.value;
  },
  updateAllInstructions(state, payload) {
    state.product.children.forEach(child => {
      payload.forEach((translation, index) => {
        if (
          translation.redeem_instruction !== undefined &&
          translation.redeem_instruction !== null &&
          translation.redeem_instruction !== ""
        ) {
          child.translations[index].redeem_instruction =
            translation.redeem_instruction;
        }
      });
    });
  },
  /**
   * @param state
   * @param {Number|String|Object} value
   * @param {String} path
   * @param {Number} index
   */
  updateChild(state, { value, path, index }) {
    _.set(state.product.children[index], path, value);
  },
  clearAllChildren(state) {
    state.product.children = [];
  },
  reset(state) {
    // acquire initial state
    const s = initialState();
    Object.keys(s).forEach(key => {
      state[key] = s[key];
    });
  },
  updateCategoryList(state, payload) {
    state.product.categories = payload;
  },
  updateMode(state, payload) {
    state.mode = payload;
  },
  updateChildQty(state, payload) {
    const type = payload.type;
    const qty = payload.qty;
    const products = _.cloneDeep(state.children);
    const product = products[payload.index];

    switch (type) {
      case 0:
        // Add
        product.qty += qty;
        break;
      case 1:
        // Min
        product.qty -= qty;
        break;
      case 2:
        // Set to
        product.qty = qty;
        break;
    }

    state.children = [];
    state.children = products;
  },
  updateVendor(state, payload) {
    state.product.vendor = [payload];
  }
};

export const getters = {
  getProductField,
  getProductCategories: state => {
    return state.product.categories;
  }
};
