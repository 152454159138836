import { createHelpers } from "vuex-map-fields";

const { getCustomerField, updateCustomerField } = createHelpers({
  getterType: "getCustomerField",
  mutationType: "updateCustomerField"
});

const contact = {
  contact_first_name: "",
  contact_last_name: "",
  email: "",
  area_code: "+86",
  phone: "",
  receiver_first_name: "",
  receiver_last_name: "",
  address_line_1: "",
  address_line_2: "",
  city: "",
  region: "",
  country: "",
  isDefault: true
};

function initialState() {
  return {
    customer: {
      first_name: "",
      last_name: "",
      full_name: "",
      email: "",
      active: true,
      accept_marketing: true,
      spent_amount: 0,
      order_count: 0,
      contacts: []
    },
    mode: "create"
  };
}

export const state = () => initialState();

export const mutations = {
  updateCustomerField,
  updateCustomer(state, payload) {
    state.customer = payload;
    // in edit mode, add an empty contact to the contact list if customer doesn't own any contact
    // if (state.customer.contacts.length === 0) {
    //   let temp = _.cloneDeep(contact)
    //   state.customer.contacts.push(temp)
    // }
  },
  addEmptyContacts(state) {
    const temp = _.cloneDeep(contact);
    temp.isDefault = false;
    state.customer.contacts.push(temp);
  },
  setMode(state, mode) {
    state.mode = mode;
  },
  /**
   * @param state
   * @param {int} index
   */
  deleteContacts(state, index) {
    // if contact id exist, it's already created on backend, mark remove instead of directly
    // remove it or it won't be actually deleted on backend
    if (_.get(state, `customer.contacts[${index}].id`, null) !== null) {
      const temp = _.cloneDeep(state.customer.contacts[index]);
      temp.remove = 1;
      state.customer.contacts.splice(index, 1, temp);
    } else {
      // if contact id doesn't exist, it's not created on backend, ok to directly remove it
      state.customer.contacts.splice(index, 1);
    }
  },
  setContactsDefault(state, selectedIndex) {
    // remove other contacts default setting if one is selected
    state.customer.contacts.forEach((value, index) => {
      if (index === selectedIndex) {
        value.isDefault = 1;
      }
      if (index !== selectedIndex && value.isDefault === 1) {
        value.isDefault = 0;
      }
    });
  },
  reset(state) {
    // acquire initial state
    const s = initialState();
    Object.keys(s).forEach(key => {
      state[key] = s[key];
    });
  }
};

export const getters = {
  getCustomerField
};
