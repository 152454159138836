import { createHelpers } from "vuex-map-fields";

const { getSalesOrderField, updateSalesOrderField } = createHelpers({
  getterType: "getSalesOrderField",
  mutationType: "updateSalesOrderField"
});
/**
 * @typedef SalesOrder
 * @type {object}
 * @property {object} contact
 * @property {string} contact.contact_phone_area_code
 * @property {string} contact.contact_phone
 * @property {string} contact.contact_email
 * @property {string} contact.contact_first_name
 * @property {string} contact.contact_last_name
 * @property {object} ship_to
 * @property {string} ship_to.shipping_address_line_1
 * @property {string} ship_to.shipping_address_line_2
 * @property {string} ship_to.receiver_first_name
 * @property {string} ship_to.receiver_last_name
 * @property {string} ship_to.postal_code
 * @property {object} line_items
 */
export const state = () => ({
  /** @type {SalesOrder} */
  salesOrder: {
    contact: {
      contact_phone_area_code: "",
      contact_phone: "",
      contact_email: "",
      contact_first_name: "",
      contact_last_name: ""
    },
    ship_to: {
      postal_code: "",
      country: "",
      region: "",
      city: "",
      shipping_address_line_1: "",
      shipping_address_line_2: "",
      receiver_first_name: "",
      receiver_last_name: ""
    },
    line_items: {}
  },
  salesOrderFulfilments: [],
  selectedInstruction: null,
  selectedLineItem: null
});

export const mutations = {
  updateSalesOrderField,
  /**
   * @param {object} state
   * @param {object} payload
   */
  updateSalesOrder(state, payload) {
    state.salesOrder = {
      ...state.salesOrder,
      ...payload
    };
  },
  updateSalesOrderFulfilment(state, payload) {
    state.salesOrderFulfilments = _.cloneDeep(payload);
  },
  /**
   * @param {object} state
   * @param {object} payload
   */
  updateSalesOrderShipTo(state, payload) {
    state.salesOrder.ship_to = payload;
  },
  /**
   * @param {object} state
   * @param {object} payload
   */
  updateSalesOrderContact(state, payload) {
    state.salesOrder.contact = payload;
  }
};

export const getters = {
  getSalesOrderField
};
