export default {
  moduleName: "Stock / SKU",
  searchLabel: "Stock Name / SKU #",
  createButton: "Add SKU",
  tableHeader: {
    name: "SKU Name",
    sku: "SKU #",
    remainStock: "Remaining Stock",
    reservedStock: "Reserved Stock",
    editRemainStock: "Edit Remaining Stock",
    deliveredStock: "Delivered Stock",
    action: "Actions"
  },
  table: {
    button: {
      updateStockQty: "Save",
      set: "Set",
      add: "Add"
    },
    updateQtySuccess: "Successfully updated quantity!"
  },
  form: {
    name: "SKU Name",
    sku: "SKU #",
    remainStock: "Remaining Stock",
    reservedStock: "Reserved Stock",
    editRemainStock: "Edit Remaining Stock"
  }
};
