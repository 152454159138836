export default {
  en: {
    currency: {
      style: "currency",
      currency: "CNY",
      currencyDisplay: "narrowSymbol"
    }
  },
  zh_HK: {
    currency: {
      style: "currency",
      currency: "CNY",
      currencyDisplay: "narrowSymbol"
    }
  },
  zh_CN: {
    currency: {
      style: "currency",
      currency: "CNY",
      currencyDisplay: "narrowSymbol"
    }
  }
};
