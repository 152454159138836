export default {
  moduleName: "供应商",
  searchLabel: "供应商名称",
  createButton: "新增供应商",
  tableHeader: {
    name: "供应商名称",
    actions: "行动"
  },
  name: "供应商名称",
  enabled: "启用"
};
