import { createHelpers } from "vuex-map-fields";

const { getVendorField, updateVendorField } = createHelpers({
  getterType: "getVendorField",
  mutationType: "updateVendorField"
});

function initialState() {
  return {
    vendor: {
      name: "",
      email: "",
      phone: "",
      area_code: "+86",
      address_line_1: "",
      address_line_2: "",
      region: "",
      country: "",
      city: "",
      active: 1
    }
  };
}

export const state = () => initialState();

export const mutations = {
  updateVendorField,
  updateVendor(state, payload) {
    state.vendor = payload;
  },
  reset(state) {
    // acquire initial state
    const s = initialState();
    Object.keys(s).forEach(key => {
      state[key] = s[key];
    });
  }
};

export const getters = {
  getVendorField
};
