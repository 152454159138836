export default {
  moduleName: "客户和用户",
  searchLabel: "客户名称/电话号码",
  createButton: "建立客户",
  tableHeader: {
    name: "客户/用户名",
    amount: "消费金额",
    order: "订单数",
    actions: "行动"
  },
  form: {
    tab: {
      overview: "总览",
      order: "相关订单"
    },
    firstName: "名",
    lastName: "姓",
    email: "电邮",
    emailCannotEdit: "Email cannot be changed once the record is created",
    phone: "电话号码",
    active: "啟用",
    accept: "接受营销",
    shipping: {
      title: "收件地址",
      contactFirstName: "联系人名",
      contactLastName: "联系人姓",
      receiverFirstName: "收件人名",
      receiverLastName: "收件人姓",
      address1: "地址1",
      address2: "地址2",
      city: "市",
      region: "省/地区",
      country: "国家"
    },
    relatedOrderTableHeader: {
      uuid: "#",
      name: "顾客姓名",
      currency: "货币",
      total: "总",
      balance: "结余",
      created_at: "建立日期",
      pay_status: "支付状态",
      ful_status: "出貨状态"
    }
  }
};
