import { createHelpers } from "vuex-map-fields";

const { getCategoryField, updateCategoryField } = createHelpers({
  getterType: "getCategoryField",
  mutationType: "updateCategoryField"
});

function initialState() {
  return {
    category: {
      enabled: true,
      parent: null,
      thumbnail_path: null,
      translations: [
        {
          name: "",
          locale: ""
        }
      ]
    },
    tempThumbnail: {}
  };
}

export const state = () => initialState();

export const mutations = {
  updateCategoryField,
  updateCategory(state, payload) {
    state.category = payload;
  },
  updateThumbnailPath(state, payload) {
    state.category.thumbnail_path = payload;
  },
  updateCategoryLocale(state, locale) {
    state.category.translations[0].locale = locale;
  },
  updateCategoryParentID(state, parentID) {
    state.category.parent_id = parentID;
  },
  updateTempThumbnail(state, payload) {
    state.tempThumbnail = _.cloneDeep(payload);
  },
  deleteThumbnail(state) {
    state.category.thumbnail_path = null;
    state.tempThumbnail = {};
  },
  reset(state) {
    // acquire initial state
    const s = initialState();
    Object.keys(s).forEach(key => {
      state[key] = s[key];
    });
  }
};

export const getters = {
  getCategoryField
};
