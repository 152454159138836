import { createHelpers } from "vuex-map-fields";

const { getSettingField, updateSettingField } = createHelpers({
  getterType: "getSettingField",
  mutationType: "updateSettingField"
});

const language = {
  code: "en",
  id: 1,
  name: "英文",
  remove: false
};

const defaultLanguage = {
  active: false
};

const otherCurrency = {
  id: 1,
  currency_code: "HKD",
  currency_name: "港幣",
  rate: 1,
  active: false,
  remove: false
};

const option = {
  value: 0
};

const category = {
  categories: [],
  images: []
};

const product = {
  products: [],
  images: []
};

const url = {
  value: "https//example.com",
  images: []
};

const defaultBanner = () => {
  return {
    image: "",
    banner_path: "",
    page_prefix: "/pages/category/show?id=",
    target: "",
    redirect_url: ""
  };
};

export const bannerLinkTypeOptions = [
  {
    text: "Categories's Product Page",
    search: "/cms/v1/categories/list",
    path: "/pages/category/show?id="
  },
  {
    text: "Specific Product",
    search: "/cms/v1/products/list",
    path: "/pages/product/show?id="
  },
  // External Link need to be the last one for loop path prefix
  {
    text: "External Link",
    search: "",
    path: ""
  }
];

function initialState() {
  return {
    setting: {
      general: {
        translations: [
          {
            locale: "en",
            name: "Popo Store",
            description: ""
          }
        ],
        email: "aa",
        phone: "66666666",
        area_code: "+852"
      },
      shipping: {
        fixed_shipping_fee: 0,
        free_shipping_amount: 0,
        enabled: false
      },
      defaultLanguageCode: "",
      default_language: {},
      defaultLanguages: [],
      languages: [],
      default_currency: {},
      defaultCurrencyCode: "",
      otherCurrencies: [],
      appearance: {
        icon: {},
        icon_path: "",
        og_image: {},
        og_image_path: "",
        bannerLinkTypeOptions,
        banners: [],
        themes: [],
        theme_id: "",
        color_scheme: {}
      }
    },
    mode: "edit"
  };
}

export const state = () => initialState();

export const mutations = {
  updateSettingField,
  updateIconImage(state, payload) {
    state.setting.appearance.icon = payload;
  },
  updateIconPath(state, payload) {
    state.setting.appearance.icon_path = payload;
  },
  removeIconImage(state) {
    state.setting.appearance.icon = {};
    state.setting.appearance.icon_path = null;
  },
  updateOgImage(state, payload) {
    state.setting.appearance.og_image = payload;
  },
  updateOgImagePath(state, payload) {
    state.setting.appearance.og_image_path = payload;
  },
  removeOgImage(state) {
    state.setting.appearance.og_image = {};
    state.setting.appearance.og_image_path = null;
  },
  uploadCategoryImageFile(state, payload) {
    state.setting.appearance.categoryOption[payload.optionIndex].images.push(
      payload
    );
  },
  removeCategoryImageFile(state, payload) {
    state.setting.appearance.categoryOption[payload.optionIndex].images.splice(
      payload.index,
      1
    );
  },
  uploadProductImageFile(state, payload) {
    state.setting.appearance.productOption[payload.optionIndex].images.push(
      payload
    );
  },
  removeProductImageFile(state, payload) {
    state.setting.appearance.productOption[payload.optionIndex].images.splice(
      payload.index,
      1
    );
  },
  uploadUrlImageFile(state, payload) {
    state.setting.appearance.urlOption[payload.optionIndex].images.push(
      payload
    );
  },
  removeUrlImageFile(state, payload) {
    state.setting.appearance.urlOption[payload.optionIndex].images.splice(
      payload.index,
      1
    );
    state.setting.appearance.icon = "";
    state.setting.appearance.icon_path = "";
  },
  uploadBannerImage(state, payload) {
    state.setting.appearance.banners[payload.optionIndex].image = payload.image;
    state.setting.appearance.banners[payload.optionIndex].banner_path =
      payload.banner_path;
  },
  updateBannerSort(state, payload) {
    state.setting.appearance.banners[payload.optionIndex].sort_order =
      payload.sort_order;
  },
  removeBannerImage(state, payload) {
    state.setting.appearance.banners[payload.optionIndex].image = "";
    state.setting.appearance.banners[payload.optionIndex].banner_path = "";
  },
  addEmptyLanguage(state) {
    state.setting.languages.push(_.cloneDeep(language));
    state.setting.defaultLanguages.push(_.cloneDeep(defaultLanguage));
  },
  addEmptyOtherCurrency(state) {
    state.setting.otherCurrencies.push(_.cloneDeep(otherCurrency));
  },
  addEmptySlide(state) {
    state.setting.appearance.options.push(_.cloneDeep(option));
    state.setting.appearance.categoryOption.push(_.cloneDeep(category));
    state.setting.appearance.productOption.push(_.cloneDeep(product));
    state.setting.appearance.urlOption.push(_.cloneDeep(url));
  },
  deleteSlide(state, index) {
    state.setting.appearance.options.splice(index, 1);
    state.setting.appearance.categoryOption.splice(index, 1);
    state.setting.appearance.productOption.splice(index, 1);
    state.setting.appearance.urlOption.splice(index, 1);
  },
  addEmptyBanner(state) {
    state.setting.appearance.banners.push(defaultBanner());
  },
  deleteBanner(state, index) {
    state.setting.appearance.banners.splice(index, 1);
  },
  setDefaultLanguages(state, payload) {
    state.setting.defaultLanguages = payload;
  },
  setLanguages(state, payload) {
    state.setting.languages = payload;
  },
  setOtherCurrencies(state, payload) {
    state.setting.otherCurrencies = payload;
  },
  setMode(state, mode) {
    state.mode = mode;
  },
  setColorTheme(state, payload) {
    for (const property in payload) {
      state.setting.appearance.color_scheme[property] = payload[property];
    }
  },
  setBanner(state, payload) {
    if (payload.target !== undefined) {
      state.setting.appearance.banners[payload.optionIndex].target =
        payload.target;
    }
    if (payload.page_prefix !== undefined) {
      state.setting.appearance.banners[payload.optionIndex].page_prefix =
        payload.page_prefix;
    }
    if (payload.redirect_url !== undefined) {
      state.setting.appearance.banners[payload.optionIndex].redirect_url =
        payload.redirect_url;
    }
  }
};

export const getters = {
  getSettingField,
  getSlideLength(state) {
    return state.setting.appearance.options.length;
  }
};
