<template>
  <div>
    <v-app-bar-nav-icon @click.stop="drawer = !drawer" />

    <v-navigation-drawer v-model="drawer" temporary app class="navigation-bar">
      <v-row>
        <v-col cols="1" offset="9">
          <v-menu>
            <template v-slot:activator="{ on, attrs }">
              <v-icon color="primary" dark v-bind="attrs" v-on="on">
                mdi-chevron-down
              </v-icon>
            </template>
            <v-list>
              <v-list-item @click="logout">
                <v-list-item-title>Logout</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </v-col>
      </v-row>

      <v-divider />
      <v-list>
        <div class="text-right p-3">
          <v-icon @click.stop="drawer = !drawer">
            mdi mdi-arrow-left
          </v-icon>
        </div>
        <v-list-item-group
          v-model="group"
          active-class="deep-purple--text text--accent-4"
        >
          <v-list-item
            v-for="(route, index) in routeList"
            :key="index"
            @click="goToPage(route.routeName)"
          >
            <v-list-item-title>{{ route.displayName }}</v-list-item-title>
          </v-list-item>
        </v-list-item-group>
        <div v-if="userIsSuperAdmin">
          <v-divider />
          <h4 class="internal-menu-title">
            For internal admin only
          </h4>
          <v-list-item-group
            v-model="group"
            active-class="deep-purple--text text--accent-4"
          >
            <v-list-item>
              <v-list-item-title @click="goToPage('internal-store')">
                Stores
              </v-list-item-title>
            </v-list-item>
            <v-list-item>
              <v-list-item-title @click="goToPage('internal-allStock')">
                Selected Store All Stock
              </v-list-item-title>
            </v-list-item>
            <v-list-item>
              <v-list-item-title @click="goToPage('internal-deliveryCompany')">
                Delivery Company
              </v-list-item-title>
            </v-list-item>
          </v-list-item-group>
        </div>
      </v-list>
      <template v-slot:append>
        <v-list-item-group active-class="deep-purple--text text--accent-4">
          <v-list-item>
            <v-list-item-title @click="goToPage('setting')">
              {{ $t("general.menu.setting") }}
            </v-list-item-title>
          </v-list-item>
        </v-list-item-group>
        <v-select
          v-model="selectedStore"
          class="store-select"
          :items="storeList"
          item-value="id"
          :label="$t('general.store')"
          outlined
          @change="changeStore"
        >
          <template slot="item" slot-scope="data">
            {{ data.item.translations[0].name }}
          </template>
          <template slot="selection" slot-scope="data">
            {{ data.item.translations[0].name }}
          </template>
        </v-select>
      </template>
    </v-navigation-drawer>
  </div>
</template>

<style scoped>
.internal-menu-title {
  padding: 16px;
}
.store-select {
  padding-left: 16px;
  padding-right: 16px;
}
.navigation-bar {
  height: 100vh;
}
</style>

<script>
import userPermissionCheckMixin from "~/components/reusable/userPermissionCheckMixin";

const excludeRoutes = ["index", "setting"];
export default {
  mixins: [userPermissionCheckMixin],
  data: () => ({
    drawer: false,
    group: null,
    selectedStore: null
  }),
  computed: {
    routeList() {
      const routes = [];
      for (const route of this.$router.options.routes) {
        if (!route.name.includes("-")) {
          const displayName = this.$t(`general.menu.${route.name}`);
          // check if the route need to be excluded
          if (
            !excludeRoutes.includes(route.name) &&
            this.checkServiceRouteAvailability(route.name)
          ) {
            routes.push({
              displayName,
              routeName: route.name
            });
          }
        }
      }
      return routes;
    },
    storeList() {
      return this.$store.state.storeList;
    },
    /**
     * Menu items which only display if store service is enabled
     * @returns {Array}
     */
    serviceRoutes() {
      return [
        {
          routeName: "liveBroadcast",
          enabled: this.$store.getters
            .getSelectedStoreLiveBroadcastServiceEnabled
        },
        {
          routeName: "datetime",
          enabled: this.$store.getters.getSelectedStoreDateTimeServiceEnabled
        }
      ];
    }
  },
  watch: {
    group() {
      this.drawer = false;
    }
  },
  created() {
    this.selectedStore = parseInt(this.$store.state.storeID);
  },
  methods: {
    checkServiceRouteAvailability(routeName) {
      const routeIsServiceRoute = this.serviceRoutes.find(
        serviceRoute => serviceRoute.routeName === routeName
      );
      // always return true if the route is not a route require service enabled
      if (routeIsServiceRoute === undefined) {
        return true;
      }
      // if the route is a route which require service enabled, check if it's enabbled, if enabled then allow to display
      if (routeIsServiceRoute !== undefined) {
        return routeIsServiceRoute.enabled;
      }
    },
    async updateStoreDetail() {
      const response = await this.$apiFetch.stores.getStoreDetail(
        this.selectedStore
      );
      this.$store.commit("setSelectedStoreDetail", response.data);
    },
    async changeStore() {
      this.$store.commit("setStoreID", this.selectedStore);
      await this.updateStoreDetail();
      await this.goToPage("index");
    },
    async goToPage(route) {
      await this.$router.push({ name: route });
    },
    async logout() {
      await this.$apiFetch.logout();
      this.$store.commit("user/updateUser", {});
      this.$store.commit("reset");
      await this.$router.push({ name: "auth-login" });
    }
  }
};
</script>
