export default {
  moduleName: "產品",
  searchLabel: "產品名稱/ SKU /類別",
  createButton: "添加產品",
  tableHeader: {
    product_name: "產品名稱",
    sku: "SKU",
    original_price: "劃線價格",
    discounted_price: "未劃線價格",
    name: "產品",
    price: "價錢",
    inventory: "庫存",
    category: "類別",
    actions: "行動"
  },
  form: {
    tab: {
      overview: "總覽",
      option: "產品選項",
      stock: "庫存管理"
    },
    overview: {
      title: "產品標題",
      description: "描述",
      category: "類別",
      type: "產品類別",
      region: "地區",
      available: "可出售",
      images: "圖片",
      imageHints: "以拖放排列圖片的順序",
      noImage: "沒有已上傳圖片",
      price: "價錢",
      addImages: "新增圖片",
      vendor: "供應商",
      selectVendor: "選擇供應商",
      selectCategory: "選擇類別",
      inventory: {
        title: "庫存",
        sku: "SKU",
        stockQty: "可供貨數量",
        vendor: "供應商",
        fulfillment: "按需出貨",
        ps: "僅在第三方直接出貨訂單時才選擇此選項",
        issuing: {
          title: "電子優惠發行",
          no: "沒有優惠券",
          qty: "發行每張優惠",
          line: "發行每個訂單項的優惠"
        }
      },

      additional: {
        title: "附加信息要求",
        require: "每次購買都需要輸入的附加信息",
        list: {
          name: "姓名",
          local: "手機號碼（本地）",
          oversea: "手機號碼（海外）",
          passport: "護照號碼",
          sex: "性別",
          address: "地址",
          remarks: "備註"
        }
      },
      blog: "相關博客",
      selectBlog: "選擇博客",
      blogModal: {
        title: "選擇產品",
        search: "博客名稱",
        selected: "{0} 已選擇的項目"
      }
    },
    options: {
      withChild: "此產品有多個選項，如不同的大小、顏色等",
      title: "產品選項",
      subTitle: "第一組選項將於顧客購物時用作數量選取之用",
      categories: "類別",
      productCategories: "產品選頂顯示名稱",
      values: "分類名",
      valuesPlacehoder: "按Enter鍵或逗號鍵(,)以分隔選項",
      edit: "編輯選項",
      variant: "子產品管理",
      enabled: "已啟用",
      childProductName: "子產品名",
      editOption: {
        title: "編輯產品選項",
        delete: "刪除",
        qtySelect: "管理此類別庫存",
        addOption: "添加產品選項",
        variantGenerate: "變體生成",
        cannotDelete: "已創建的變體法刪除",
        optionMissing: "產品選項不能留空",
        emptyOption: "請新增最少一項產品選項",
        variants: {
          enabled: "已啟用",
          new: "新變體？",
          delete: "刪除",
          keep: "保留此選項"
        },
        newVariant: {
          title: "新變體-SKU鏈接",
          subTitle: "當產品變體售出時，將扣減與變體連結的SKU的存貨量",
          name: "變體名稱",
          createNewSKU: {
            title: "創建新SKU?",
            create: "創建",
            useExist: "選擇舊有SKU"
          },
          remainStock: "存貨量",
          confirmCreateNewSKU: "創建",
          skuName: "SKU 名稱",
          sku: "SKU #",
          changeLinkage: {
            label: "改變鏈接",
            title: "選擇SKU",
            add: "添加更多SKU"
          }
        }
      },
      salesSetting: {
        title: "價格及銷售設置",
        pricing: {
          title: "定價模式",
          daily: {
            label: "每日租借",
            ps:
              "項目折扣/稅前的總價格為: <br/>Sum of each day's rental price * quantity<br/>用戶需要選擇購買的開始日期和結束日期",
            shortest: "最短租期",
            longest: "最長租期"
          },
          singleDate: {
            label: "一次性價格，需要單日選擇",
            ps: "折扣/稅前的總價格為:<br/>Unit Price * Quantity"
          },
          singleDateTime: {
            label: "一次性價格，需要選擇單個日期和時間",
            ps: "折扣/稅前的總價格為:<br/>Unit Price * Quantity"
          },
          noDate: {
            label: "一次性價格，無日期選擇",
            ps: "折扣/稅前的總價格為:<br/>Unit Price * Quantity"
          },
          availableUntil: "有效期至",
          daysBefore: "服務開始日期的前幾天"
        },
        priceSetting: {
          title: "價格設定",
          date: "在 :date 的價格",
          dateTime: "在 :date 時間的價格",
          dateTimePs: "沒有時隙的日期不可出售",
          available: "可供出售",
          startTime: "開始時間",
          endTime: "結束時間",
          unitPriceB4Discount: "折扣前單價",
          actualUnitPrice: "實際單價",
          unitCost: "單位成本",
          skuQty: "SKU 數量",
          setQty: "將數量設置為",
          add: "增加更多時隙"
        },
        bulkUpdatePrice: {
          title: "大量更新價格",
          unitPriceB4Discount: "折扣前單價",
          actualUnitPrice: "實際單價",
          unitCost: "單位成本"
        },
        bulkUpdateSetting: {
          title: "大量更新“可供出售”設置",
          available: "可供出售",
          notAvailable: "不可出售"
        }
      },
      availability: {
        title: "庫存量",
        unlimited: "無限庫存量",
        ps: "如果此選項不是在禁售期而且沒有缺貨，則可以隨時出售",
        addNewAvailablePeriod: "添加新的可用期間"
      },
      blackout: {
        title: "禁售",
        addNewBlackoutPeriod: "新增禁售期間組"
      },
      instruct: {
        title: "兌換指示"
      },
      bulkPopupCommon: {
        applyToProducts: "適用於產品"
      },
      bulkPriceUpdate: {
        title: "大量價格更新",
        permanent: "永久",
        unitPriceBeforeDiscount: "折扣前單價",
        actualUnitPrice: "實際單價",
        unitCost: "單位成本"
      },
      bulkAddBlackout: {
        title: "大量添加禁售"
      },
      bulkAddAvailability: {
        title: "大量添加庫存量",
        period: "期間"
      }
    },
    stock: {
      classify: "按日期分類庫存",
      slot: "按日期和時間段管理庫存",
      ps: "以下項目每天都有單獨的存貨",
      variant: "變體管理",
      manage: "庫存管理",
      log: "庫存日誌",
      sold: "售罄",
      logTableHeader: {
        operate: "操作日期和時間",
        source: "來源",
        uuid: "#",
        usage: "進／出",
        qty: "數量",
        balance: "結餘",
        operator: "操作員"
      },
      addQty: "添加",
      deductQty: "扣除",
      setQty: "設置",
      qtyRemain: "剩餘數量: :qty"
    }
  }
};
