const name = "直播名稱";
export default {
  moduleName: "直播",
  searchLabel: "直播名稱",
  tableHeader: {
    name,
    channel_id: "直播間",
    start_at: "開始時間",
    display_on_landing: "顯示於首頁",
    actions: "行動"
  },
  /**
   * Live broadcast object
   */
  name,
  start_at: "開始時間",
  /**
   * Form
   */
  form: {
    lbl_name: "Room Title",
    lbl_image: "Cover Photo",
    lbl_logo: "Logo",
    lbl_cover: "Cover",
    lbl_sales_assistant_qr_code: "Sales Assistant QR Code",
    lbl_sales_assistant_qr_code_upload_hint:
      "Should be 1:1 ratio, less than 2MB\nOptional, user could contact you by scanning this QR code.",
    lbl_cover_upload_hint:
      "Size should be 1080*1920\nIt will be the background image of the live stream player when the channel is offline.",
    lbl_player_background: "Broadcast player background",
    lbl_upload: "Upload",
    lbl_upload_hint: "Should be 1:1 ratio, less than 2MB",
    lbl_start_at: "Broadcast start time",
    lbl_display_on_landing: "Display this broadcast on landing page",
    lbl_yes: "Yes",
    lbl_no: "No",
    lbl_no_hint: "User could still access to the broadcast via hyperlink",
    lbl_product_list: "Product list for this live broadcast"
  },
  controlPanel: {
    shortTitle: "Control Panel",
    title: "Live Broadcast Control Panel",
    push: "Push product",
    actionSuccess: "Action success!",
    dismiss: "Dismiss Product"
  }
};
