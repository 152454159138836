export default function({ app, $axios }, inject) {
  // Create a custom axios instance
  const headers = {
    "X-Requested-With": "XMLHttpRequest",
    Accept: "application/json"
  };

  const api = $axios.create({
    baseURL: process.env.WEB_URL,
    withCredentials: true,
    headers: {
      "Content-Type": "application/json",
      ...headers
    }
  });

  const formDataReq = $axios.create({
    baseURL: process.env.WEB_URL,
    withCredentials: true,
    headers: {
      "Content-Type": "multipart/form-data",
      ...headers
    }
  });

  const fulfilledCallback = function(response) {
    app.store.commit("setLoginStatus", true);
    return response;
  };
  const rejectedCallback = async function(error) {
    const status = _.get(error, "response.status", undefined);

    if (status === 401 || status === undefined) {
      app.store.commit("setLoginStatus", false);
      app.store.commit("user/updateUser", {});
      await app.router.push("/auth/login");
      return Promise.reject(error);
    } else {
      return Promise.reject(error);
    }
  };

  // hard-coded list for i18n locale to api request header X-localization
  const localeList = {
    en: "en",
    zh_HK: "zh-hk",
    zh_CN: "zh-cn"
  };

  // handle add store id and x-localization to header before request
  api.interceptors.request.use(function(config) {
    if (app.store.state.storeID !== null) {
      config.headers["store-id"] = app.store.state.storeID;
      config.headers["x-localization"] = localeList[app.i18n.locale];
    }
    return config;
  });

  formDataReq.interceptors.request.use(function(config) {
    if (app.store.state.storeID !== null) {
      config.headers["store-id"] = app.store.state.storeID;
      config.headers["x-localization"] = localeList[app.i18n.locale];
    }
    return config;
  });

  // handle session timeout
  api.interceptors.response.use(
    function(response) {
      return fulfilledCallback(response);
    },
    function(error) {
      return rejectedCallback(error);
    }
  );

  // handle session timeout
  formDataReq.interceptors.response.use(
    function(response) {
      return fulfilledCallback(response);
    },
    function(error) {
      return rejectedCallback(error);
    }
  );

  // Inject to context as $api
  inject("api", api);
  inject("formDataReq", formDataReq);
}
