export default {
  store: "店舖",
  menu: {
    salesOrder: "訂單",
    category: "分類",
    discount: "折扣",
    customer: "顧客",
    product: "產品",
    datetime: "產品日期/時段",
    stock: "存貨/SKU",
    vendor: "供應商",
    liveBroadcast: "直播",
    setting: "設定"
  }
};
