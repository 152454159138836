import { createHelpers } from "vuex-map-fields";

const { getDeliveryCompanyField, updateDeliveryCompanyField } = createHelpers({
  getterType: "getDeliveryCompanyField",
  mutationType: "updateDeliveryCompanyField"
});

function initialState() {
  return {
    deliveryCompany: {
      translations: [],
      website: ""
    },
    mode: ""
  };
}

export const state = () => initialState();

export const mutations = {
  updateDeliveryCompanyField,
  setCreateMode(state) {
    state.mode = "create";
  },
  addTranslations(state, payload) {
    state.deliveryCompany.translations.push(payload);
  },
  reset(state) {
    // acquire initial state
    const s = initialState();
    Object.keys(s).forEach(key => {
      state[key] = s[key];
    });
  }
};

export const actions = {
  initCreateMode({ commit }, localeList) {
    commit("deliveryCompany/reset");
    commit("deliveryCompany/setCreateMode");
    localeList.forEach(locale => {
      commit("addTranslations", {
        locale: locale.code,
        name: ""
      });
    });
  }
};

export const getters = {
  getDeliveryCompanyField
};
