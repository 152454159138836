export default {
  moduleName: "日期和时间",
  bulkActionDialog: {
    steps: {
      one: "Select Action Type",
      two: "Input Data",
      three: "Confirm Result"
    },
    mode: {
      price: "Bulk Edit Price",
      availableForSale: "Bulk Edit “Available for sale” setting",
      dateTimeSlot: "Bulk Add Date / Timeslot",
      blackout: "Bulk Add Blackout setting"
    }
  },
  fieldName: {
    startDate: "Start Date",
    endDate: "End Date",
    startTime: "Start Time",
    endTime: "End Time"
  }
};
