export default {
  moduleName: "产品",
  searchLabel: "产品名称/ SKU /类别",
  createButton: "添加产品",
  tableHeader: {
    name: "产品",
    price: "价钱",
    inventory: "库存",
    category: "类别",
    actions: "行动"
  },
  form: {
    tab: {
      overview: "总览",
      option: "产品选择",
      stock: "库存管理"
    },
    overview: {
      title: "产品标题",
      description: "描述",
      category: "类别",
      type: "产品类别",
      region: "地区",
      available: "可出售",
      images: "图片",
      imageHints: "以拖放排列图片的顺序",
      noImage: "没有已上传图片",
      price: "价钱",
      addImages: "新增图片",
      inventory: {
        title: "库存",
        sku: "SKU",
        stockQty: "可供货数量",
        vendor: "供应商",
        fulfillment: "按需出貨",
        ps: "仅在第三方直接出貨订单时才选择此选项",
        issuing: {
          title: "电子优惠发行",
          no: "没有优惠",
          qty: "发行每张优惠",
          line: "发行每个订单项的优惠"
        }
      },
      hotRecommend: {
        title: "於首頁顯示此產品",
        sortOrder: "排序"
      },
      additional: {
        title: "附加信息要求",
        require: "每次购买都需要输入的附加信息",
        list: {
          name: "姓名",
          local: "手机号码（本地）",
          oversea: "手机号码（海外）",
          passport: "护照号",
          sex: "性别",
          address: "地址",
          remarks: "备注"
        }
      },
      blog: "相关博客",
      selectBlog: "选择博客",
      blogModal: {
        title: "选择产品",
        search: "博客名称",
        selected: "{0} 选择的项目"
      }
    },
    options: {
      withChild: "此产品有多个选项，如不同的大小、颜色等",
      title: "选择的项目",
      subTitle: "第一组选项将于顾客购物时用作数量选取之用",
      categories: "类别",
      values: "价值",
      valuesPlacehoder: "按Enter键或逗号键(,)以分隔选项",
      edit: "编辑选项",
      variant: "版本管理",
      enabled: "已启用",
      editOption: {
        title: "编辑产品选项",
        delete: "删除",
        qtySelect: "选择数量",
        addOption: "添加产品选项",
        variantGenerate: "Variant generate",
        cannotDelete: "创建的版本无法删除",
        optionMissing: "产品选项不能留空",
        emptyOption: "请新增最少一项产品选项",
        variants: {
          enabled: "已启用",
          new: "新版本？",
          delete: "删除",
          keep: "保留此选项"
        },
        newVariant: {
          title: "新版本-SKU链接",
          subTitle: "当产品变体售出时，将扣减与变体连结的SKU的存货量",
          auto: "自动为新版本创建临时SKU",
          link: "手动链接SKU",
          name: "版本名称",
          skuName: "SKU 名称",
          sku: "SKU #",
          remainStock: "存货量",
          createNewSKU: {
            title: "創建新SKU?",
            create: "創建",
            useExist: "選擇舊有SKU"
          },
          changeLinkage: {
            label: "改变链接",
            title: "选择SKU",
            add: "添加更多SKU"
          }
        }
      },
      salesSetting: {
        title: "销售设置",
        pricing: {
          title: "定价模式",
          daily: {
            label: "每日租金",
            ps:
              "项目折扣/税前的总价格为： <br/>Sum of each day's rental price * quantity<br/>用戶需要選擇購買的開始日期和結束日期",
            shortest: "最短租期",
            longest: "最长租期"
          },
          singleDate: {
            label: "一次性价格，需要单日选择",
            ps: "折扣/税前的总价为：<br/>Unit Price * Quantity"
          },
          singleDateTime: {
            label: "一次性价格，需要选择单个日期和时间",
            ps: "折扣/税前的总价为：<br/>Unit Price * Quantity"
          },
          noDate: {
            label: "一次性价格，无日期选择",
            ps: "折扣/税前的总价为：<br/>Unit Price * Quantity"
          },
          availableUntil: "有效期至",
          daysBefore: "服务开始日期的前几天"
        },
        priceSetting: {
          title: "价格设定",
          date: "在 :date 价格",
          dateTime: "在：date时间的价格",
          dateTimePs: "没有时隙的日期不可出售",
          available: "可供出售",
          startTime: "开始时间",
          endTime: "结束时间",
          unitPriceB4Discount: "折扣前单价",
          actualUnitPrice: "实际单价",
          unitCost: "单位成本",
          skuQty: "SKU 数量",
          setQty: "将数量设置为",
          add: "增加更多时隙"
        },
        bulkUpdatePrice: {
          title: "大量更新价格",
          unitPriceB4Discount: "折扣前单价",
          actualUnitPrice: "实际单价",
          unitCost: "单位成本"
        },
        bulkUpdateSetting: {
          title: "大量更新“可供出售”设置",
          available: "可供出售",
          notAvailable: "不可出售"
        }
      },
      availability: {
        title: "库存量",
        unlimited: "无限库存量",
        ps: "如果此选项不是在禁售期而且没有缺货，则可以随时出售",
        addNewAvailablePeriod: "添加新的可用期间组"
      },
      blackout: {
        title: "禁售",
        addNewBlackoutPeriod: "新增禁售期间组"
      },
      instruct: {
        title: "兑换指示"
      },
      bulkPopupCommon: {
        applyToProducts: "适用于产品"
      },
      bulkPriceUpdate: {
        title: "大量价格更新",
        permanent: "永久",
        unitPriceBeforeDiscount: "折扣前单价",
        actualUnitPrice: "实际单价",
        unitCost: "单位成本"
      },
      bulkAddBlackout: {
        title: "大量添加禁售"
      },
      bulkAddAvailability: {
        title: "大量添加库存量",
        period: "期间"
      }
    },
    stock: {
      classify: "按日期分类库存",
      slot: "按日期和时间段管理库存",
      ps: "以下项目每天都有单独的存货",
      variant: "版本管理",
      manage: "库存管理",
      log: "库存日志",
      sold: "售罄",
      logTableHeader: {
        operate: "操作日期和时间",
        source: "来源",
        uuid: "#",
        usage: "进／出",
        qty: "数量",
        balance: "结余",
        operator: "操作员"
      },
      addQty: "增加",
      deductQty: "扣除",
      setQty: "设置",
      qtyRemain: "剩余数量： :qty"
    }
  }
};
