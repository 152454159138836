import moment from "moment";

// TODO: refactor to common place when other pages support TypeScript
export interface Error {
  message: string;
  errors?: object; // unsure which fields will be included in this object
}
export interface CalendarDateTime {
  /**
   * id is from server API response
   * record not saved to server won't have id
   **/
  stock_id?: number | null;
  /**
   * For Bulk Action to mark which timeslot matchs the filter criteria
   */
  matchCriteria?: boolean;
  /**
   * For import csv feature to which record should be deleted
   */
  keepAfterCSVImport?: boolean;
  /**
   * For import csv feature to determine which column is new column
   */
  newRecord?: boolean;
  date: string;
  original_unit_price: number | null;
  discount_unit_price: number | null;
  unit_cost: number | null;
  available_for_sale: boolean;
  remain_stock: number;
}
export interface CalendarTimeSlot extends CalendarDateTime {
  date_time_from: string;
  date_time_to: string;
}

export interface CalendarDate extends CalendarDateTime {
  date_time_from?: null;
  date_time_to?: null;
}

export function isCalendarTimeSlot(object: any): object is CalendarTimeSlot {
  return (
    object?.date_time_from !== undefined &&
    object?.date_time_from !== null &&
    object?.date_time_to !== undefined &&
    object?.date_time_to !== null
  );
}

export function isCalendarDate(object: any): object is CalendarDate {
  return (
    object?.date_time_from === undefined ||
    object?.date_time_from === null ||
    object?.date_time_to === undefined ||
    object?.date_time_to === null
  );
}

export type TimeSlot = CalendarDate | CalendarTimeSlot;

export type SaleablePeriods = Array<TimeSlot>;

export interface CalendarEvent {
  id?: number | null;
  date: string;
  title: string;
  name: string;
  start: string | number | Date;
  end?: string | number | Date | null;
  color: string;
  timed: boolean;
}

export interface DefaultValues {
  original_unit_price: number;
  discount_unit_price: number;
  unit_cost: number;
  available_for_sale: boolean;
}

export interface BlackoutPeriod {
  id?: number | null;
  date_times: Array<{
    date_time_from: string;
    date_time_to: string;
  }>;
  time_ranges: Array<{
    time_from: string;
    time_to: string;
  }>;
  sun: boolean;
  mon: boolean;
  tue: boolean;
  wed: boolean;
  thu: boolean;
  fri: boolean;
  sat: boolean;
}

export interface ApiDateTimeFormat {
  pricing_model: number;
  default_values: DefaultValues;
  blackout_periods: Array<BlackoutPeriod>;
  saleable_periods: SaleablePeriods;
}

export interface ShowEventParameter {
  nativeEvent: Event;
  event: CalendarEvent;
}

export enum DaysOfWeek {
  Monday = 1,
  Tuesday = 2,
  Wednesday = 3,
  Thursday = 4,
  Friday = 5,
  Saturday = 6,
  Sunday = 7
}

export enum RecurrenceMode {
  Day,
  Week,
  Month
}

export interface RecurrenceSetting {
  startDate: moment.Moment;
  endDate: moment.Moment;
  value: number;
  recurrenceMode: RecurrenceMode;
  repeatOnDaysOfWeek: Array<DaysOfWeek> | DaysOfWeek;
}

export interface DateRange {
  startDate: moment.Moment;
  endDate: moment.Moment;
}

export interface TimeRange {
  startTime: string;
  endTime: string;
}

export enum BulkEditMode {
  Price,
  AvailableForSale,
  AddNewSlot
}

export interface productDateTimeStore {
  dateTimes: ApiDateTimeFormat;
  calendarEvents: Array<CalendarDate> | Array<CalendarTimeSlot>;
}

export interface TimeSlotFilterCriteria {
  dateRanges: Array<DateRange>;
  timeRanges: Array<TimeRange>;
  daysOfWeek: Array<DaysOfWeek>;
}

export interface TimeSlotFilterCriteriaWithChangeData
  extends TimeSlotFilterCriteria {
  original_unit_price: number | null;
  discount_unit_price: number | null;
  unit_cost: number | null;
  available_for_sale: boolean;
}
