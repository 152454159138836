export default {
  moduleName: "日期和時間",
  bulkActionDialog: {
    steps: {
      one: "Select Action Type",
      two: "Input Data",
      three: "Confirm Result"
    },
    mode: {
      price: "Bulk Edit Price",
      availableForSale: "Bulk Edit “Available for sale” setting",
      dateTimeSlot: "Bulk Add Date / Timeslot",
      blackout: "Bulk Add Blackout setting"
    },
    availableForSale: "Available for sale",
    notAvailableForSale: "Not available for sale",
    priceHints: "Leave blank if no change is needed",
    dateAndTmeRange: "Date & Time Range",
    TimeRangeEveryDay: "Time Range Every Day",
    DaysOfWeek: "Days of the week"
  },
  fieldName: {
    startDate: "Start Date",
    endDate: "End Date",
    startTime: "Start Time",
    endTime: "End Time"
  }
};
