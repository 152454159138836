export default {
  moduleName: "订单",
  filteringButton: {
    all: "所有",
    draft: "草稿",
    unfulfilled: "未完成/部分完成",
    completed: "已完成"
  },
  searchLabel: "订单号/客户名称",
  createButton: "创建订单",
  tableHeader: {
    contact_first_name: "顾客名",
    contact_name: "顾客姓名",
    contact_last_name: "顾客姓",
    currency_code: "货币",
    grand_total: "总额",
    total_amount_paid: "付款额",
    order_date_time: "建立日期",
    paid: "订单已支付？",
    fulfilled: "出货状态",
    actions: "行动"
  },
  form: {
    sidebar: {
      searchCustomer: "搜索客户...",
      contact: "联系",
      shipTo: "运送到",
      edit: "编辑",
      contactPopup: {
        title: "更改联系资料",
        firstName: "名字",
        lastName: "姓",
        phone: "电话号码",
        email: "电邮"
      },
      shippingPopup: {
        title: "更改联系资料",
        firstName: "名字",
        lastName: "姓",
        phone: "电话号码",
        address1: "地址 1",
        address2: "地址 2",
        address3: "地址 3",
        city: "市",
        country: "国家"
      }
    },
    tabs: {
      order: "订单",
      fulfillment: "出貨"
    },
    itemTableHeader: {
      item: "项目",
      unitPrice: "单价",
      quantity: "数量",
      fulfilled: "已完成",
      qtyWithoutFulfilmentRecord: "未出货数量",
      total: "共计"
    },
    notes: "注释",
    addNote: "添加注释...",
    actionButtonPanel: {
      fulfillOrder: "出貨订单",
      markAsPaid: "标记为已付款",
      refund: "退款",
      exportAsPdf: "导出为PDF",
      cancelOrder: "取消订单",
      sendOrder: "发送订单给客户"
    },
    amountPanel: {
      discount: "折扣",
      subTotal: "小计",
      shipping: "运输",
      total: "共计",
      paidBy: "支付方式",
      paymentMethod: {
        weChat: "微信"
      },
      refund: "退款"
    },
    formFulfillment: {
      trackingInfo: "追踪信息",
      addTrackingInfo: "添加追踪信息...",
      addTrackingNumber: "添加提单号",
      attachVoucher: "附加优惠",
      editVoucher: "查看/编辑优惠",
      confirmFulfillment: "确认出貨",
      cancelFulfillment: "取消出貨",
      delivering: "出货中",
      delivered: "已送达",
      cancelDialogMessage: "是否要取消此出货记录？",
      deliveringDialogMessage: "确认将此出货记录状态更新为「出货中」？",
      deliveredDialogMessage: "确认将此出货记录状态更新为「已送达」？"
    },
    popup: {
      productName: "产品名称..."
    },
    fulfillOrderPopup: {
      title: "出貨",
      quantity: "数量",
      of: "的",
      confirmFulfillment: "确认出貨"
    },
    refundPopup: {
      title: "退款",
      refundReason: "退款原因（仅限内部）",
      restoreItems: "恢复退款项目",
      refund: "退款"
    },
    voucherPopup: {
      title: "附加优惠",
      voucherNumber: "优惠号码",
      attach: "附上"
    }
  }
};
