// This vuex store is for Internal admin's store management page - don't mixed up with store data for normal store owner
import { createHelpers } from "vuex-map-fields";

const { getStoreField, updateStoreField } = createHelpers({
  getterType: "getStoreField",
  mutationType: "updateStoreField"
});

const currency = () => {
  return {
    id: 0,
    currency_code: "",
    currency_name: "",
    rate: 1,
    created: false
  };
};

const locale = () => {
  return {
    code: "",
    id: 0,
    name: "",
    created: false
  };
};

const variable = () => {
  return {
    service: "",
    key: "",
    value: ""
  };
};

const domain = () => {
  return {
    domain: ""
  };
};

// Default script with gtag_id
const script = () => {
  return {
    gtag_id: null
  };
};

function initialState() {
  return {
    store: {
      locales: [],
      currencies: [],
      translations: [],
      payment_methods: [],
      services: [],
      variables: [],
      domains: [],
      script: script()
    },
    mode: "",
    defaultCurrencyExist: false,
    defaultLocaleExist: false,
    fullServicesList: [],
    fullPaymentMethodsList: []
  };
}

export const state = () => initialState();

export const mutations = {
  updateStoreField,
  defaultCurrencyExist(state) {
    state.defaultCurrencyExist = true;
  },
  defaultCurrencyNotExist(state) {
    state.defaultCurrencyExist = false;
  },
  defaultLocaleExist(state) {
    state.defaultLocaleExist = true;
  },
  defaultLocaleNotExist(state) {
    state.defaultLocaleExist = false;
  },
  setModeToEdit(state) {
    state.mode = "edit";
  },
  setModeToCreate(state) {
    state.mode = "create";
  },
  updateStore(state, payload) {
    state.store = _.cloneDeep(payload);
  },
  updateFullServicesList(state, payload) {
    state.fullServicesList = _.cloneDeep(payload);
  },
  updateFullPaymentMethodsList(state, payload) {
    state.fullPaymentMethodsList = _.cloneDeep(payload);
  },
  addEmptyCurrencyToStore(state) {
    state.store.currencies.push(currency());
  },
  addEmptyLocaleToStore(state) {
    state.store.locales.push(locale());
  },
  addEmptyVariableToStore(state) {
    state.store.variables.push(variable());
  },
  addEmptyDomainToStore(state) {
    state.store.domains.push(domain());
  },
  deleteLocaleFromStoreLocales(state, index) {
    state.store.locales.splice(index, 1);
  },
  deleteCurrencyFromStoreCurrencies(state, index) {
    state.store.currencies.splice(index, 1);
  },
  replaceCurrencyInCurrencies(state, [index, newCurrency]) {
    state.store.currencies.splice(index, 1, _.cloneDeep(newCurrency));
  },
  replaceLocaleInLocales(state, [index, newLocale]) {
    state.store.locales.splice(index, 1, _.cloneDeep(newLocale));
  },
  updateTranslations(state, translations) {
    state.store.translations = _.cloneDeep(translations);
  },
  deleteTranslation(state, index) {
    state.store.translations.splice(index, 1);
  },
  updateServices(state, services) {
    state.store.services = _.cloneDeep(services);
  },
  updatePaymentMethods(state, payment_methods) {
    state.store.payment_methods = _.cloneDeep(payment_methods);
  },
  updateServiceInServices(state, [index, newService]) {
    state.store.services[index] = _.cloneDeep(newService);
  },
  deleteServiceFromServices(state, index) {
    state.store.services.splice(index, 1);
  },
  addServiceInServices(state, service) {
    state.store.services.push(_.cloneDeep(service));
  },
  updatePaymentMethodInPaymentMethods(state, [index, newPaymentMethod]) {
    state.store.payment_methods[index] = _.cloneDeep(newPaymentMethod);
  },
  deletePaymentMethodFromPaymentMethods(state, index) {
    state.store.payment_methods.splice(index, 1);
  },
  addPaymentMethodInPaymentMethods(state, payment_method) {
    state.store.payment_methods.push(_.cloneDeep(payment_method));
  },
  reset(state) {
    // acquire initial state
    const s = initialState();
    Object.keys(s).forEach(key => {
      state[key] = s[key];
    });
  }
};

export const getters = {
  getStoreField
};

export const actions = {
  updateStoreDataAndFullServiceAndPaymentMethodList(
    { commit },
    { storeData, fullServicesList, fullPaymentMethodsList }
  ) {
    commit("updateStore", storeData);
    commit("updateFullServicesList", fullServicesList);
    commit("updateFullPaymentMethodsList", fullPaymentMethodsList);
  }
};
