export default {
  moduleName: "庫存 / SKU",
  searchLabel: "SKU名稱 / SKU #",
  createButton: "添加SKU",
  tableHeader: {
    name: "SKU名稱",
    sku: "SKU #",
    remainStock: "可供貨庫存",
    reservedStock: "保留庫存",
    editRemainStock: "修改可供貨庫存",
    deliveredStock: "已送貨",
    action: "行動"
  },
  table: {
    button: {
      updateStockQty: "儲存",
      set: "設定",
      add: "增加"
    },
    updateQtySuccess: "修改庫存數量成功"
  },
  form: {
    name: "SKU名稱",
    sku: "SKU #",
    remainStock: "可供貨庫存",
    reservedStock: "保留庫存",
    editRemainStock: "修改可供貨庫存"
  }
};
