import { createHelpers } from "vuex-map-fields";

const { getLiveBroadcastField, updateLiveBroadcastField } = createHelpers({
  getterType: "getLiveBroadcastField",
  mutationType: "updateLiveBroadcastField"
});

function initialState() {
  return {
    liveBroadcast: {
      store_id: null,
      display_on_landing: 0,
      start_at: null,
      logo_path: null,
      cover_path: null,
      thumbnail_path: null,
      sales_assistant_qr_code_path: null,
      live_products: [],
      translations: [
        {
          name: "",
          locale: ""
        }
      ]
    },
    tempLogo: {},
    tempCover: {},
    tempSalesAssistantQrCode: {}
  };
}

export const state = () => initialState();

export const mutations = {
  updateLiveBroadcastField,
  updateLiveBroadcast(state, payload) {
    state.liveBroadcast = _.cloneDeep(payload);
  },
  updateLiveBroadcastLocale(state, locale) {
    state.liveBroadcast.translations[0].locale = locale;
  },
  updateLiveBroadcastLiveProducts(state, liveProducts) {
    state.liveBroadcast.live_products = _.cloneDeep(liveProducts);
  },
  deleteProductFromLiveProducts(state, liveProductIndex) {
    state.liveBroadcast.live_products.splice(liveProductIndex, 1);
  },
  updateSalesAssistantQrCodePath(state, payload) {
    state.liveBroadcast.sales_assistant_qr_code_path = payload;
  },
  updateTempSalesAssistantQrCode(state, payload) {
    state.tempSalesAssistantQrCode = _.cloneDeep(payload);
  },
  updateLogoPath(state, payload) {
    state.liveBroadcast.logo_path = payload;
  },
  updateTempLogo(state, payload) {
    state.tempLogo = _.cloneDeep(payload);
  },
  updateCoverPath(state, payload) {
    state.liveBroadcast.cover_path = payload;
  },
  updateTempCover(state, payload) {
    state.tempCover = _.cloneDeep(payload);
  },
  removeLogo(state) {
    state.tempLogo = {};
    state.liveBroadcast.logo_path = null;
  },
  removeCover(state) {
    state.tempCover = {};
    state.liveBroadcast.cover_path = null;
  },
  removeSalesAssistantQrCode(state) {
    state.tempSalesAssistantQrCode = {};
    state.liveBroadcast.sales_assistant_qr_code_path = null;
  },
  reset(state) {
    // acquire initial state
    const s = initialState();
    Object.keys(s).forEach(key => {
      state[key] = s[key];
    });
  }
};

export const getters = {
  getLiveBroadcastField
};
