import { createHelpers } from "vuex-map-fields";

const { getStockField, updateStockField } = createHelpers({
  getterType: "getStockField",
  mutationType: "updateStockField"
});

function initialState() {
  return {
    stock: {
      name: "",
      sku: "",
      remain_stock: 0
    },
    mode: "create"
  };
}

export const state = () => initialState();

export const mutations = {
  updateStockField,
  setEditMode(state) {
    state.mode = "edit";
  },
  setCreateMode(state) {
    state.mode = "create";
  },
  updateStock(state, payload) {
    state.stock = payload;
  },
  reset(state) {
    // acquire initial state
    const s = initialState();
    Object.keys(s).forEach(key => {
      state[key] = s[key];
    });
  }
};

export const getters = {
  getStockField
};
