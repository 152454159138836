export default {
  moduleName: "Sales Order",
  filteringButton: {
    all: "All",
    draft: "Draft",
    unfulfilled: "Unfulfilled / Partially Fulfilled",
    completed: "Completed"
  },
  searchLabel: "Order Number / Customer Name",
  createButton: "Create Order",
  paymentStatus: "Payment Status",
  orderStatus: "Order Status",
  fulfilmentStatus: "Fulfilment Status",
  tableHeader: {
    contact_first_name: "Customer First Name",
    contact_name: "Customer Name",
    contact_last_name: "Customer Last Name",
    currency_code: "Currency",
    grand_total: "Grand Total",
    total_amount_paid: "Paid Total",
    order_date_time: "Create Date",
    paid: "Order Paid?",
    fulfilled: "Fulfil Status",
    orderStatus: "Order Status",
    actions: "Actions"
  },
  table: {
    loadingText: "Loading..."
  },
  form: {
    sidebar: {
      searchCustomer: "Search Customer...",
      contact: "Contact",
      shipTo: "Ship to",
      edit: "Edit",
      contactPopup: {
        title: "Edit Contact",
        firstName: "First Name",
        lastName: "Last Name",
        phone: "Phone Number",
        email: "E-mail"
      },
      shippingPopup: {
        title: "Edit Contact",
        firstName: "First Name",
        lastName: "Last Name",
        phone: "Phone Number",
        address1: "Address 1",
        address2: "Address 2",
        address3: "Address 3",
        city: "City",
        country: "Country"
      }
    },
    tabs: {
      order: "Order",
      fulfillment: "Fulfillment"
    },
    itemTableHeader: {
      item: "Item",
      discount: "Discount",
      unitPrice: "Unit Price",
      quantity: "Quantity",
      fulfilled: "Fulfilled Qty",
      qtyWithoutFulfilmentRecord: "Undelivered Qty",
      total: "Total"
    },
    notes: "Notes",
    addNote: "Add a note...",
    actionButtonPanel: {
      fulfillOrder: "Fulfill Order",
      markAsPaid: "Mark as Paid",
      refund: "Refund",
      exportAsPdf: "Export as PDF",
      cancelOrder: "Cancel Order",
      sendOrder: "Send Order to Customer"
    },
    amountPanel: {
      discount: "Discount",
      subTotal: "Sub-total",
      shipping: "Shipping",
      total: "Total",
      paidBy: "Paid by",
      paymentMethod: {
        weChat: "WeChat"
      },
      refund: "Refund"
    },
    additionalInformation: {
      dialog: {
        button: "Additional Information",
        title: "View Additional Information"
      }
    },
    formFulfillment: {
      trackingInfo: "Tracking Information",
      addTrackingInfo: "Add a tracking information...",
      addTrackingNumber: "Add tracking number",
      attachVoucher: "Attach Voucher",
      editVoucher: "View / Edit Voucher",
      confirmFulfillment: "Confirm Fulfillment",
      cancelFulfillment: "Cancel Fulfillment",
      delivering: "Delivering",
      delivered: "Delivered",
      cancelDialogMessage: "Are you sure you want to cancel this fulfilment?",
      deliveringDialogMessage:
        "Are you sure you want to mark this fulfilment as 'Delivering'?",
      deliveredDialogMessage:
        "Are you sure you want to mark this fulfilment as 'Delivered'?"
    },
    popup: {
      productName: "Product Name..."
    },
    fulfillOrderPopup: {
      title: "Fulfillment",
      quantity: "Quantity",
      of: "of",
      confirmFulfillment: "Confirm Fulfillment"
    },
    refundPopup: {
      title: "Refund",
      refundReason: "Refund Reason (Internal Only)",
      restoreItems: "Restore Refunded Items",
      refund: "Refund"
    },
    voucherPopup: {
      title: "Attach Voucher",
      voucherNumber: "Voucher Number",
      attach: "Attach"
    }
  }
};
