export default {
  moduleName: "Reward And Discount",
  searchLabel: "Discount Name",
  createButton: "Create Reward / Discount",
  tableHeader: {
    name: "Reward / Discount Name",
    performance: "Performance",
    status: "Status",
    actions: "Actions"
  },
  form: {
    tab: {
      overview: "Overview",
      outcome: "Outcome"
    },
    title: "Title",
    description: "Description",
    enable: "Enabled",
    trigger: {
      title: "Trigger Criteria",
      orderTotal: "Order Total",
      specificProduct: "Specific Products",
      totalQtyRequirement: "Total Quantity requirement",
      specificCustomer: "Specific Customer",
      specificDay: "Specific Day of the Week",
      specificDayRange: "Specific Date range",
      addDateRange: "Add Date Range",
      couponCode: "Coupon Code",
      from: "From",
      to: "To"
    },
    stackability: {
      title: "Stackability",
      all: "Stackable to all",
      discount: "Stackable to Specific Discount",
      nonAll: "Non-stackable to all",
      nonDiscount: "Non-stackable to Specific Discount"
    },
    outcome: {
      option: "Outcome Option",
      type: "Value Type",
      value: "Value",
      includedQty: "Include Quantity",
      issueQty: "Issue Quantity",
      qty: "Quantity",
      override: "Override Item Price",
      addOutcome: "Add New Outcome"
    }
  }
};
