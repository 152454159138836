export default {
  moduleName: "奖励和折扣",
  searchLabel: "折扣名称",
  createButton: "创建奖励/折扣",
  tableHeader: {
    name: "奖励/折扣名称",
    performance: "表现",
    status: "状态",
    actions: "行动"
  },
  form: {
    tab: {
      overview: "总览",
      outcome: "结果"
    },
    title: "标题",
    description: "描述",
    enable: "已启用",
    trigger: {
      title: "触发条件",
      orderTotal: "合计订单",
      specificProduct: "具体产品",
      totalQtyRequirement: "总数量要求",
      specificCustomer: "具体客户",
      specificDay: "星期几",
      specificDayRange: "具体日期范围",
      addDateRange: "添加日期范围",
      couponCode: "优惠券代码",
      from: "由",
      to: "到"
    },
    stackability: {
      title: "可堆叠性",
      all: "可全部堆叠",
      discount: "可堆叠至特定折扣",
      nonAll: "全部不可堆叠",
      nonDiscount: "不可堆叠至特定折扣"
    },
    outcome: {
      option: "结果选项",
      type: "值类型",
      value: "值",
      includedQty: "包括数量",
      issueQty: "发行数量",
      qty: "数量",
      override: "替代商品价格",
      addOutcome: "添加新结果"
    }
  }
};
