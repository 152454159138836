import { liveBroadcast, dateTime } from "~/assets/js/availableServiceList";

function initialState() {
  return {
    images: [],
    token: null,
    login: true,
    eCommerce: true,
    sessionCheckTimeoutID: null,
    storeID: null,
    storeList: [],
    selectedStoreDetail: {}
  };
}

export const state = () => initialState();

export const mutations = {
  uploadImage(state, payload) {
    state.images.push(payload);
  },
  setLoginStatus(state, payload) {
    state.login = payload;
  },
  setSessionTimeoutID(state, payload) {
    state.sessionCheckTimeoutID = payload;
  },
  setStoreID(state, payload) {
    // the store id will be stored to localstorage too
    localStorage.setItem("selectedStoreID", payload);
    state.storeID = payload;
  },
  setSelectedStoreDetail(state, payload) {
    state.selectedStoreDetail = payload;
  },
  setSelectedStoreDetailServices(state, payload) {
    state.selectedStoreDetail.services = payload;
  },
  /**
   * store list will be updated on middleware storeIDHandler and when user clicking the store list
   * @param state
   * @param payload
   */
  setStoreList(state, payload) {
    state.storeList = _.cloneDeep(payload);
  },
  reset(state) {
    // acquire initial state
    const s = initialState();
    Object.keys(s).forEach(key => {
      state[key] = s[key];
    });
  }
};

export const getters = {
  getLoginStatus(state) {
    return state.login;
  },
  getSessionCheckTimeoutID(state) {
    return state.sessionCheckTimeoutID;
  },
  getSelectedStoreLocales(state) {
    return _.get(state.selectedStoreDetail, "locales", []);
  },
  getSelectedStoreLogo(state) {
    return state.selectedStoreDetail?.store_logo;
  },
  getSelectedOgImage(state) {
    return state.selectedStoreDetail?.og_image;
  },
  /**
   * Check if the selected store has broadcast service enabled
   */
  getSelectedStoreLiveBroadcastServiceEnabled(state) {
    const services = _.get(state.selectedStoreDetail, "services", []);
    return services.some(service => {
      return service.code === liveBroadcast;
    });
  },
  /**
   * Check if the selected store has Date / Timeslot enabled
   */
  getSelectedStoreDateTimeServiceEnabled(state) {
    const services = _.get(state.selectedStoreDetail, "services", []);
    return services.some(service => {
      return service.code === dateTime;
    });
  }
};

export const actions = {
  /**
   * Fetch store detail from api and commit to store.
   * The store id from localstorage should always be reliable since there's a checking mechanism done by the middleware storeData.js
   *
   * @param context
   * @returns {Promise<void>}
   */
  async updateSelectedStoreDetail(context) {
    const localStorageSelectedStoreID = localStorage.getItem("selectedStoreID");
    const response = await this.$apiFetch.stores.getStoreDetail(
      localStorageSelectedStoreID
    );
    context.commit("setSelectedStoreDetail", response.data);
  }
};
