export default {
  moduleName: "库存 / SKU",
  searchLabel: "SKU名称 / SKU #",
  createButton: "添加SKU",
  tableHeader: {
    name: "SKU名称",
    sku: "SKU #",
    remainStock: "可供货库存",
    reservedStock: "保留库存",
    editRemainStock: "修改可供货库存",
    deliveredStock: "已送货",
    action: "行动"
  },
  table: {
    button: {
      updateStockQty: "储存",
      set: "设定",
      add: "增加"
    },
    updateQtySuccess: "修改库存数量成功"
  },
  form: {
    name: "SKU名称",
    sku: "SKU #",
    remainStock: "可供货库存",
    reservedStock: "保留库存",
    editRemainStock: "修改可供货库存"
  }
};
