import { createHelpers } from "vuex-map-fields";
import { PricingModel } from "@/constant/product.ts";
// eslint-disable-next-line @typescript-eslint/no-unused-vars,no-unused-vars
import { SaleablePeriods, productDateTimeStore } from "@/types/datetime";

const { getDateTimeField, updateDateTimeField } = createHelpers({
  getterType: "getDateTimeField",
  mutationType: "updateDateTimeField"
});

/**
 * @returns {productDateTimeStore}
 */
function initialState() {
  return {
    pricingModel: PricingModel.RegularPricing,
    dateTimes: null,
    productId: null,
    calendarEvents: [],
    error: ""
  };
}
/** @type {function(): {dates: ApiDateTimeFormat, timeSlots: ApiDateTimeFormat, calendarEvents: CalendarEvent[]}}  **/
export const state = () => initialState();

export const mutations = {
  updateDateTimeField,
  /**
   * @param {productDateTimeStore} state
   * @param {Array<CalendarEvent>} payload
   */
  updateCalendarEvents(state, payload) {
    state.calendarEvents = payload;
  },
  /**
   * @param {productDateTimeStore} state
   * @param {CalendarEvent} payload
   */
  createCalendarEvent(state, payload) {
    state.calendarEvents?.push(payload);
  },
  /**
   * @param {productDateTimeStore} state
   * @param {{oldEventIndex: number, newEvent: CalendarEvent}} payload
   */
  replaceCalendarEvent(state, payload) {
    if (payload.oldEventIndex !== -1 && payload.newEvent) {
      state.calendarEvents.splice(payload.oldEventIndex, 1, payload.newEvent);
    }
  },
  /**
   * @param {productDateTimeStore} state
   * @param {number} index
   */
  deleteCalendarEvent(state, index) {
    if (index !== -1) {
      state.calendarEvents.splice(index, 1);
    }
  },
  /**
   * @param {productDateTimeStore} state
   * @param {ApiDateTimeFormat} payload
   */
  updateDateTime(state, payload) {
    state.dateTimes = payload;
  },
  /**
   * @param {productDateTimeStore} state
   * @param {CalendarDateTime} payload
   */
  createDateTime(state, payload) {
    state.dateTimes?.saleable_periods?.push(payload);
  },
  /**
   * @param {productDateTimeStore} state
   * @param {SaleablePeriods} payload
   */
  updateSaleablePeriods(state, payload) {
    state.dateTimes.saleable_periods = payload;
  },
  /**
   * @param {productDateTimeStore} state
   * @param {{oldPeriodIndex: number, newPeriod: CalendarTimeSlot}} payload
   */
  replaceDateTime(state, payload) {
    if (payload.oldPeriodIndex !== -1 && payload.newPeriod) {
      state.dateTimes?.saleable_periods?.splice(
        payload.oldPeriodIndex,
        1,
        payload.newPeriod
      );
    }
  },
  /**
   * @param {productDateTimeStore} state
   * @param {number} index
   */
  deleteDateTime(state, index) {
    if (index !== -1) {
      state.dateTimes?.saleable_periods?.splice(index, 1);
    }
  },

  reset(state) {
    // acquire initial state
    const s = initialState();
    Object.keys(s).forEach(key => {
      state[key] = s[key];
    });
  }
};

export const getters = {
  getDateTimeField
};
