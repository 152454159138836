import { createHelpers } from "vuex-map-fields";
import moment from "moment";

const { getDiscountField, updateDiscountField } = createHelpers({
  getterType: "getDiscountField",
  mutationType: "updateDiscountField"
});

// Select Items
// Overview

const categories = [
  {
    text: "Selected Category",
    value: 0
  }
];

const customers = [
  {
    text: "Selected Customers",
    value: 0
  }
];

const products = [
  {
    text: "Selected Product",
    value: 0
  }
];

const days = [
  {
    text: "Monday",
    value: 0
  },
  {
    text: "Tuesday",
    value: 1
  },
  {
    text: "Wednesday",
    value: 2
  },
  {
    text: "Thursday",
    value: 3
  },
  {
    text: "Friday",
    value: 4
  },
  {
    text: "Saturday",
    value: 5
  },
  {
    text: "Sunday",
    value: 6
  }
];

const discounts = [
  {
    text: "Select Discount",
    value: 0
  }
];

// Outcome

const excludeCategories = [
  {
    text: "Exclude Category",
    value: 0
  }
];

const excludeProducts = [
  {
    text: "Exclude Products",
    value: 0
  }
];

const selectCategories = [
  {
    text: "Select Category",
    value: 0
  }
];

const selectProducts = [
  {
    text: "Select Products",
    value: 0
  }
];

const selectAddProducts = [
  {
    text: "Select Products",
    value: 0
  }
];

const offOrder = {
  discount_outcome_type: 0,
  value_type: 0,
  value: 100,
  excluded_categories: [],
  excluded_products: []
};

const offItem = {
  display: false,
  value_type: 0,
  value: 120,
  include_quantity: 0,
  select_category: 0,
  select_product: 0
};

const offShipFee = {
  display: false,
  value_type: 0,
  value: 333
};

const addItem = {
  display: false,
  select_add_product: 0,
  quantity: 444,
  item_price: 4444
};

/**
 * @type {{display: boolean, active: boolean, order_total_value: number, operator: number}}
 */
const orderTotalCriteria = {
  enabled: false,
  operator: 0,
  order_total_value: 0
};

const dateInDateRangeCriteria = {
  type: 0,
  date_from: moment()
    .format("YYYY-MM-DD")
    .toString(),
  date_to: moment()
    .format("YYYY-MM-DD")
    .toString(),
  // 2 toggle property is for controlling the input box display
  // need to be removed before submit data to server
  toggle_date_from_input: false,
  toggle_date_to_input: false,
  remove: false
};

const dateRangeCriteria = {
  date_ranges: [_.cloneDeep(dateInDateRangeCriteria)],
  enabled: false
};

const discountCodeCriteria = {
  discount_code: "",
  enabled: false
};

function initialState() {
  return {
    discount: {
      enabled: true,
      translations: [],
      order_total_criteria: _.cloneDeep(orderTotalCriteria),
      date_range_criteria: _.cloneDeep(dateRangeCriteria),
      discount_code_criteria: _.cloneDeep(discountCodeCriteria),
      outcomes: [offOrder],
      stackability_display: false,
      mode: "create"
    },
    selections: {
      overview: {
        categories: [_.cloneDeep(categories)],
        customers: [_.cloneDeep(customers)],
        products: [_.cloneDeep(products)],
        days: [_.cloneDeep(days)],
        discounts: [_.cloneDeep(discounts)],
        order_total_operators: [],
        date_range_types: []
      },
      outcome: {
        outcome_types: [],
        value_types: [],
        exclude_categories: [_.cloneDeep(excludeCategories)],
        exclude_products: [_.cloneDeep(excludeProducts)],
        select_categories: [_.cloneDeep(selectCategories)],
        select_products: [_.cloneDeep(selectProducts)],
        select_add_products: [_.cloneDeep(selectAddProducts)]
      }
    }
  };
}
export const state = () => initialState();

export const mutations = {
  updateDiscountField,
  updateDiscountTranslations(state, payload) {
    state.discount.translations = payload;
  },
  updateDiscount(state, payload) {
    state.discount = payload;
  },
  addEmptyDates(state) {
    const temp = _.cloneDeep(dateInDateRangeCriteria);
    temp.remove = false;
    state.discount.date_range_criteria.date_ranges.push(temp);
  },
  addEmptyOption(state) {
    const temp = _.cloneDeep(offOrder);
    state.discount.outcomes.push(temp);
  },
  addEmptyOffOrder(state) {
    const temp = _.cloneDeep(offOrder);
    state.discount.outcome.off_orders.push(temp);
  },
  addEmptyOffItem(state) {
    const temp = _.cloneDeep(offItem);
    state.discount.outcome.off_items.push(temp);
  },
  addEmptyOffshipFee(state) {
    const temp = _.cloneDeep(offShipFee);
    state.discount.outcome.off_ship_fees.push(temp);
  },
  addEmptyAddItem(state) {
    const temp = _.cloneDeep(addItem);
    state.discount.outcome.add_items.push(temp);
  },
  deleteOption(state, index) {
    state.discount.outcome.options.splice(index, 1);
  },
  deleteOffOrder(state, index) {
    state.discount.outcome.off_orders.splice(index, 1);
  },
  deleteOffItem(state, index) {
    state.discount.outcome.off_items.splice(index, 1);
  },
  deleteOffshipFee(state, index) {
    state.discount.outcome.off_ship_fees.splice(index, 1);
  },
  deleteAddItem(state, index) {
    state.discount.outcome.add_items.splice(index, 1);
  },
  setMode(state, mode) {
    state.mode = mode;
  },
  updateOrderTotalOperators(state, payload) {
    state.selections.overview.order_total_operators = payload;
  },
  updateDateRangeTypes(state, payload) {
    state.selections.overview.date_range_types = payload;
  },
  updateOutcomeTypes(state, payload) {
    state.selections.outcome.outcome_types = payload;
  },
  updateValueTypes(state, payload) {
    state.selections.outcome.value_types = payload;
  },
  deleteOutcomeInOutcomes(state, index) {
    state.discount.outcomes.splice(index, 1);
  },
  reset(state) {
    // acquire initial state
    const s = initialState();
    Object.keys(s).forEach(key => {
      state[key] = s[key];
    });
  }
};

export const actions = {
  addEmptyOutcomeOption({ commit }) {
    commit("addEmptyOption");
  },
  deleteOutcomeOption({ commit }, index) {
    commit("deleteOption", index);
    commit("deleteOffOrder", index);
    commit("deleteOffItem", index);
    commit("deleteOffshipFee", index);
    commit("deleteAddItem", index);
  }
};

export const getters = {
  getDiscountField,
  getDiscountOutcomesLength(state) {
    return state.discount.outcomes.length;
  }
};
