export default {
  store: "Store",
  menu: {
    salesOrder: "Sales Order",
    category: "Category",
    discount: "Discount",
    customer: "Customer",
    product: "Product",
    datetime: "Product Date / Timeslot",
    stock: "Stock / SKU",
    vendor: "Vendor",
    setting: "Setting",
    liveBroadcast: "Live Broadcast"
  }
};
