export default {
  moduleName: "Categories",
  searchLabel: "Category Name",
  createButton: "Create Category",
  tableHeader: {
    name: "Category Name",
    actions: "Actions"
  },
  name: "Category Name",
  enabled: "Enabled",
  parentCategory: "Parent Category"
};
