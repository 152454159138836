export default {
  action: "Action",
  back: "Back",
  lastStep: "Previous",
  nextStep: "Next",
  confirm_delete: "Confirm delete?",
  confirm: "Confirm",
  create: "Create",
  next: "Next",
  cancel: "Cancel",
  delete: "Delete",
  edit: "Edit",
  export: "Export",
  keyword: "Keyword",
  list: "List",
  manage: "Manage",
  no_record: "No record",
  save: "Save",
  search: "Search by keywords",
  searchByChannel: "Search by Channel",
  view: "View",
  more: "More"
};
