import auth from "./auth";
import buttons from "./buttons";
import crud from "./crud";
import customers from "./customers";
import date from "./date";
import discount from "./discount";
import salesOrder from "./sales-order";
import pagination from "./pagination";
import passwords from "./passwords";
import liveBroadcast from "./live-broadcast";
import product from "./product";
import validation from "./validation";
import category from "./category";
import vendor from "./vendor";
import general from "./general";
import stock from "./stock";
import store from "./store";
import dashboard from "./dashboard";
import datePicker from "./datePicker";
import productDateTime from "./productDateTime";
import weekDay from "./weekDay";
import color from "./color";
export default {
  general,
  auth,
  buttons,
  crud,
  customers,
  date,
  dashboard,
  datePicker,
  discount,
  salesOrder,
  store,
  pagination,
  passwords,
  liveBroadcast,
  product,
  stock,
  validation,
  category,
  vendor,
  productDateTime,
  weekDay,
  color
};
