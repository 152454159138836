export default {
  theme: {
    dark: false, // From 2.0 You have to select the theme dark or light here
    themes: {
      light: {
        primary: "#47E4B7"
      }
    }
  },
  icons: {
    iconfont: "mdiSvg" // 'mdi' || 'mdiSvg' || 'md' || 'fa' || 'fa4'
  }
};
