export default {
  moduleName: "店舖",
  searchLabel: "店舖名",
  createButton: "添加店舖",
  tableHeader: {
    name: "店舖名稱",
    email: "電子郵件",
    phone: "電話",
    action: "行動"
  },
  name: "店舖名稱",
  email: "電子郵件",
  phone: "電話",
  area_code: "電話區號",
  choose_locale_first: "請先選擇商店語言",
  address_line_1: "地址（第一行）",
  address_line_2: "地址（第二行）",
  active: "啟用",
  table: {
    button: {
      updateStockQty: "儲存",
      set: "設定",
      add: "增加"
    },
    updateQtySuccess: "修改庫存數量成功"
  },
  form: {
    name: "SKU名稱",
    sku: "SKU #",
    remainStock: "可供貨庫存",
    reservedStock: "保留庫存",
    editRemainStock: "修改可供貨庫存"
  }
};
