export default {
  moduleName: "Vendors",
  searchLabel: "Vendor Name",
  createButton: "Create Vendor",
  tableHeader: {
    name: "Vendor Name",
    actions: "Actions"
  },
  name: "Vendor Name",
  email: "Email",
  phone: "Phone Number",
  area_code: "Area Code",
  address_line_1: "Address Line 1",
  address_line_2: "Address Line 2",
  region: "Region",
  country: "Country",
  city: "City",
  active: "Active"
};
