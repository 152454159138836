export default {
  range: "日期和時間範圍",
  from: "由",
  to: "到",
  specific: "具體日期",
  addDateRange: "添加更多日期範圍",
  dayOfWeek: "星期幾",
  timeRange: "每天的時間範圍",
  addTimeSlot: "增加更多時間",
  chooseLabel: "從日曆中選擇",
  placeholder: "從菜單中選擇",
  week: {
    mon: "星期一",
    tue: "星期二",
    wed: "星期三",
    thur: "星期四",
    fri: "星期五",
    sat: "星期六",
    sun: "星期日"
  }
};
