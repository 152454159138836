export default {
  cancel: "取消",
  save: "儲存",
  confirm: "確認",
  delete: "刪除",
  next: "下一步",
  back: "上一步",
  update: "更新",
  add: "新增",
  ok: "OK",
  confirmUpdate: "確認更新"
};
