export default {
  moduleName: "類別",
  searchLabel: "類別名稱",
  createButton: "新增類別",
  tableHeader: {
    name: "類別名稱",
    actions: "行動"
  },
  name: "類別名稱"
};
