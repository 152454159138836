export default {
  action: "行动",
  back: "返回",
  confirm_delete: "确认删除？",
  confirm: "确认",
  create: "创建",
  delete: "删除",
  edit: "编辑",
  export: "编辑",
  keyword: "关键词",
  list: "清单",
  manage: "管理",
  no_record: "无记录",
  save: "保存",
  search: "按关键词搜索",
  searchByChannel: "按频道搜索",
  view: "览"
};
