export default {
  accepted: "该 :attribute 必须被接受。",
  active_url: "该 :attribute 不是有效的网址。",
  after: "该 :attribute 必须是：date之后的日期。",
  after_or_equal: "该 :attribute 必须是：date 或以后的日期",
  alpha: "该 :attribute 只能包含字母.",
  alpha_dash: "该:attribute 只能包含字母，数字，破折号和下划线。",
  alpha_num: "该 :attribute 只能包含字母和数字。",
  array: "该 :attribute 必须是一个数组。",
  before: "該 :attribute 必須是 :date 之前的日期。",
  before_or_equal: "该 :attribute 必须是在 :date 或 以后的日期",
  between: {
    numeric: "该 :attribute 必须在 :min 和 :max 之间",
    file: "该 :attribute 必须在：:min 和 :max 千字节之间.",
    string: "该 :attribute 必须在 :min 和 :max 字之间.",
    array: "该 :attribute 必须在 :min 和 :max 项目之间."
  },
  boolean: ".该 :attribute 字段必须为true或false",
  confirmed: "该 :attribute 确认不匹配",
  date: "该 :attribute 不是有效日期.",
  date_equals: "该 :attribute 日期必须等于 :date.",
  date_format: "该 :attribute 与:format 格式不符.",
  different: "该 :attribute 和 :other 必须不同.",
  digits: "该 :attribute 必须 :digits 数字",
  digits_between: "该 :attribute 必须在 :min 和 :max 数字.",
  dimensions: "该 :attribute 图片尺寸无效.",
  distinct: "该 :attribute 字段具有重复值",
  email: "该 :attribute 必须是一个有效的电邮",
  ends_with: "该 :attribute 必须以以下任一结尾: :values",
  exists: "该选择 :attribute 是无效的.",
  file: "该 :attribute 必须是一个文件。",
  filled: "该 :attribute 字段必须有一个值。",
  gt: {
    numeric: "该 :attribute 必须大于 :value.",
    file: "该 :attribute 必须大于 :value 千字节.",
    string: "该 :attribute 必须大于 :value 字.",
    array: "该 :attribute 必须超过 :value 项目."
  },
  gte: {
    numeric: "该 :attribute 必须大于或等于 :value.",
    file: "该 :attribute 必须大于或等于 :value 千字节.",
    string: "该 :attribute 必须大于或等于 :value 字.",
    array: "该 :attribute 必须具有 :value 项目或更多."
  },
  image: "该 :attribute 必须是图像。",
  in: "该选择 :attribute 是无效的",
  in_array: "该 :attribute 字段不存在于 :other.",
  integer: "该 :attribute 必须是整数.",
  ip: "该 :attribute 必须是有效的IP地址。",
  ipv4: "该 :attribute 必须是有效的IPv4地址.",
  ipv6: "该 :attribute 必须是有效的IPv6地址",
  json: "该 :attribute 必须是有效的JSON字符串。",
  lt: {
    numeric: "该 :attribute 必须小于 :value.",
    file: "该 :attribute 必须小于 :value 千字节.",
    string: "该 :attribute 必须小于 :value 字.",
    array: "该 :attribute 必须少于 :value 项目."
  },
  lte: {
    numeric: "该 :attribute 必须小于或等于 :value",
    file: "该 :attribute 必须小于或等于 :value 千字节.",
    string: "该 :attribute 必须小于或等于 :value 字.",
    array: "该 :attribute 不得超过 :value 项目."
  },
  max: {
    numeric: "该 :attribute 不得大于 :max.",
    file: "该 :attribute 不得大于 :max 千字节.",
    string: "该 :attribute 不得大于 :max 字.",
    array: "该 :attribute 不得超过 :max 项目."
  },
  mimes: "该 :attribute 必须是: :values 类型的文件",
  mimetypes: "该 :attribute 必须是: :values 类型的文件",
  min: {
    numeric: "该 :attribute 必须至少 :min.",
    file: "该 :attribute 必须至少 :min 千字节.",
    string: "该 :attribute 必须至少 :min 字.",
    array: "该 :attribute 必须至少有 :min 项目."
  },
  not_in: "该选择 :attribute 是无效的。",
  not_regex: "该 :attribute 格式无效。",
  numeric: "该 :attribute 必须是数字。",
  present: "该 :attribute 字段必须存在。",
  regex: "该 :attribute 格式无效。",
  required: "该 :attribute 必填字段。",
  required_if: "该 :attribute 必填当 :other 是 :value.",
  required_unless: "该 :attribute 必填除非 :other 是在 :values.",
  required_with: "该 :attribute 必填当 :values 存在。",
  required_with_all: "该 :attribute 必填当 :values 存在。",
  required_without: "该 :attribute 必填当 :values 不存在。",
  required_without_all: "该 :attribute 必填当 没有 :values 存在。",
  same: "该 :attribute 和 :other 必须匹配。",
  size: {
    numeric: "该 :attribute 必须是 :size.",
    file: "该 :attribute 必须是 :size 千字节.",
    string: "该 :attribute 必须是 :size 字.",
    array: "该 :attribute 必须包含 :size 项目."
  },
  starts_with: "该 :attribute 必须以下列其中一项开头: :values",
  string: "该 :attribute 必须是一个字符串.",
  timezone: "该 :attribute 必须是有效区域.",
  unique: "该 :attribute 已经被采取。",
  uploaded: "该 :attribute 上传失败。",
  url: "该 :attribute 格式无效。",
  uuid: "该 :attribute 必须是有效的UUID。",
  custom: {
    attribute_name: {
      rule_name: "自定义消息"
    }
  },
  attributes: {}
};
