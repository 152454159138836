export default {
  salesTotal: {
    title: "銷售總額"
  },
  orderCount: {
    title: "訂單數量"
  },
  orderSalesAvg: {
    title: "平均訂單價值"
  },
  topSalesProduct: {
    title: "暢銷產品",
    productName: "產品名稱",
    totalQty: "總銷量"
  }
};
