export default {
  range: "日期和时间范围",
  from: "从",
  to: "至",
  specific: "具体日期",
  addDateRange: "添加更多日期范围",
  dayOfWeek: "星期几",
  timeRange: "每天的时间范围",
  addTimeSlot: "增加更多时间",
  chooseLabel: "从日历中选择",
  placeholder: "Picker in menu",
  week: {
    mon: "星期一",
    tue: "星期二",
    wed: "星期三",
    thur: "星期四",
    fri: "星期五",
    sat: "星期六",
    sun: "星期日"
  }
};
