export default {
  moduleName: "Customers and Users",
  searchLabel: "Customer Name / Phone No",
  createButton: "Create Customer",
  tableHeader: {
    name: "Customer / User Name",
    amount: "Spent Amount",
    order: "Order Count",
    actions: "Actions"
  },
  form: {
    tab: {
      overview: "Overview",
      order: "Related Order"
    },
    firstName: "First Name",
    lastName: "Last Name",
    email: "Email",
    emailCannotEdit: "Email cannot be changed once the record is created",
    phone: "Phone Number",
    active: "Active",
    accept: "Accept Marketing",
    shipping: {
      title: "Shipping Address",
      contactFirstName: "Contact First Name",
      contactLastName: "Contact Last Name",
      receiverFirstName: "Receiver First Name",
      receiverLastName: "Receiver Last Name",
      address1: "Address1",
      address2: "Address2",
      city: "City",
      region: "Province / Region",
      country: "Country",
      addNewContact: "Add New Contacts",
      isDefault: "Set as default contact information"
    },
    relatedOrderTableHeader: {
      uuid: "#",
      name: "Customer Name",
      currency: "Currency",
      total: "Total",
      balance: "Balance",
      created_at: "Create Date",
      pay_status: "Payment Status",
      ful_status: "Fulfillment Status"
    }
  }
};
