const middleware = {}

middleware['storeData'] = require('../middleware/storeData.js')
middleware['storeData'] = middleware['storeData'].default || middleware['storeData']

middleware['userData'] = require('../middleware/userData.js')
middleware['userData'] = middleware['userData'].default || middleware['userData']

middleware['userIsInternalAdmin'] = require('../middleware/userIsInternalAdmin.js')
middleware['userIsInternalAdmin'] = middleware['userIsInternalAdmin'].default || middleware['userIsInternalAdmin']

export default middleware
