export default {
  moduleName: "供應商",
  searchLabel: "供應商名稱",
  createButton: "新增供應商",
  tableHeader: {
    name: "供應商名稱",
    actions: "行動"
  },
  name: "供應商名稱",
  enabled: "啟用"
};
