export default {
  cancel: "取消",
  save: "保存",
  confirm: "确认",
  delete: "删除",
  next: "下一步",
  back: "上一步",
  update: "更新",
  add: "新增",
  ok: "OK",
  confirmUpdate: "确认更新"
};
