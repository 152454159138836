<template>
  <div class="tw-p-3.5 tw-bg-white tw-shadow-md tw-rounded-md tw-my-2.5">
    <slot />
  </div>
</template>

<script>
export default {
  name: "Card"
};
</script>

<style scoped></style>
