import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _7130251c = () => interopDefault(import('../pages/category/index.vue' /* webpackChunkName: "pages/category/index" */))
const _473e6008 = () => interopDefault(import('../pages/customer/index.vue' /* webpackChunkName: "pages/customer/index" */))
const _4e9ceb59 = () => interopDefault(import('../pages/datetime/index.vue' /* webpackChunkName: "pages/datetime/index" */))
const _76eab4c2 = () => interopDefault(import('../pages/discount/index.vue' /* webpackChunkName: "pages/discount/index" */))
const _a7f68526 = () => interopDefault(import('../pages/liveBroadcast/index.vue' /* webpackChunkName: "pages/liveBroadcast/index" */))
const _58841267 = () => interopDefault(import('../pages/product/index.vue' /* webpackChunkName: "pages/product/index" */))
const _f1032800 = () => interopDefault(import('../pages/salesOrder/index.vue' /* webpackChunkName: "pages/salesOrder/index" */))
const _4c1fce88 = () => interopDefault(import('../pages/setting/index.vue' /* webpackChunkName: "pages/setting/index" */))
const _a61ac9e4 = () => interopDefault(import('../pages/stock/index.vue' /* webpackChunkName: "pages/stock/index" */))
const _161904b4 = () => interopDefault(import('../pages/vendor/index.vue' /* webpackChunkName: "pages/vendor/index" */))
const _d5815446 = () => interopDefault(import('../pages/auth/login.vue' /* webpackChunkName: "pages/auth/login" */))
const _46ee6dbc = () => interopDefault(import('../pages/category/create.vue' /* webpackChunkName: "pages/category/create" */))
const _51250b7c = () => interopDefault(import('../pages/customer/create.vue' /* webpackChunkName: "pages/customer/create" */))
const _1dc363d0 = () => interopDefault(import('../pages/customer/edit.vue' /* webpackChunkName: "pages/customer/edit" */))
const _4afbd4ec = () => interopDefault(import('../pages/customer/form.vue' /* webpackChunkName: "pages/customer/form" */))
const _17034e02 = () => interopDefault(import('../pages/discount/create.vue' /* webpackChunkName: "pages/discount/create" */))
const _c6cb5f82 = () => interopDefault(import('../pages/internal/allStock/index.vue' /* webpackChunkName: "pages/internal/allStock/index" */))
const _c401c516 = () => interopDefault(import('../pages/internal/deliveryCompany/index.vue' /* webpackChunkName: "pages/internal/deliveryCompany/index" */))
const _01b55bad = () => interopDefault(import('../pages/internal/store/index.vue' /* webpackChunkName: "pages/internal/store/index" */))
const _07718a1e = () => interopDefault(import('../pages/liveBroadcast/create.vue' /* webpackChunkName: "pages/liveBroadcast/create" */))
const _409af592 = () => interopDefault(import('../pages/product/create.vue' /* webpackChunkName: "pages/product/create" */))
const _fbc858c4 = () => interopDefault(import('../pages/setting/appearance.vue' /* webpackChunkName: "pages/setting/appearance" */))
const _32adb7c4 = () => interopDefault(import('../pages/setting/general.vue' /* webpackChunkName: "pages/setting/general" */))
const _1beb5112 = () => interopDefault(import('../pages/setting/language.vue' /* webpackChunkName: "pages/setting/language" */))
const _56768e3c = () => interopDefault(import('../pages/setting/payment.vue' /* webpackChunkName: "pages/setting/payment" */))
const _fe4aff30 = () => interopDefault(import('../pages/setting/shipping.vue' /* webpackChunkName: "pages/setting/shipping" */))
const _19151170 = () => interopDefault(import('../pages/stock/create.vue' /* webpackChunkName: "pages/stock/create" */))
const _75e1a038 = () => interopDefault(import('../pages/stock/form.vue' /* webpackChunkName: "pages/stock/form" */))
const _5d9efc50 = () => interopDefault(import('../pages/vendor/create.vue' /* webpackChunkName: "pages/vendor/create" */))
const _baa14e34 = () => interopDefault(import('../pages/vendor/edit.vue' /* webpackChunkName: "pages/vendor/edit" */))
const _5f6e0aa0 = () => interopDefault(import('../pages/vendor/form.vue' /* webpackChunkName: "pages/vendor/form" */))
const _4998dbe9 = () => interopDefault(import('../pages/internal/deliveryCompany/create.vue' /* webpackChunkName: "pages/internal/deliveryCompany/create" */))
const _5caa64b1 = () => interopDefault(import('../pages/internal/store/create.vue' /* webpackChunkName: "pages/internal/store/create" */))
const _1584cfb9 = () => interopDefault(import('../pages/internal/store/form.vue' /* webpackChunkName: "pages/internal/store/form" */))
const _3a2ff91d = () => interopDefault(import('../pages/internal/deliveryCompany/_id.vue' /* webpackChunkName: "pages/internal/deliveryCompany/_id" */))
const _b75f3556 = () => interopDefault(import('../pages/internal/store/_id.vue' /* webpackChunkName: "pages/internal/store/_id" */))
const _a50682f8 = () => interopDefault(import('../pages/category/_id.vue' /* webpackChunkName: "pages/category/_id" */))
const _689aa164 = () => interopDefault(import('../pages/customer/_id.vue' /* webpackChunkName: "pages/customer/_id" */))
const _1aeff001 = () => interopDefault(import('../pages/datetime/_id.vue' /* webpackChunkName: "pages/datetime/_id" */))
const _b918f872 = () => interopDefault(import('../pages/discount/_id.vue' /* webpackChunkName: "pages/discount/_id" */))
const _23914c50 = () => interopDefault(import('../pages/liveBroadcast/_id/index.vue' /* webpackChunkName: "pages/liveBroadcast/_id/index" */))
const _1ea0bc5c = () => interopDefault(import('../pages/product/_id/index.vue' /* webpackChunkName: "pages/product/_id/index" */))
const _67b76e68 = () => interopDefault(import('../pages/salesOrder/_id.vue' /* webpackChunkName: "pages/salesOrder/_id" */))
const _d22fee14 = () => interopDefault(import('../pages/stock/_id.vue' /* webpackChunkName: "pages/stock/_id" */))
const _c31e9ce4 = () => interopDefault(import('../pages/vendor/_id.vue' /* webpackChunkName: "pages/vendor/_id" */))
const _3637be91 = () => interopDefault(import('../pages/liveBroadcast/_id/controlPanel.vue' /* webpackChunkName: "pages/liveBroadcast/_id/controlPanel" */))
const _b2dfe6f2 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/category",
    component: _7130251c,
    name: "category"
  }, {
    path: "/customer",
    component: _473e6008,
    name: "customer"
  }, {
    path: "/datetime",
    component: _4e9ceb59,
    name: "datetime"
  }, {
    path: "/discount",
    component: _76eab4c2,
    name: "discount"
  }, {
    path: "/liveBroadcast",
    component: _a7f68526,
    name: "liveBroadcast"
  }, {
    path: "/product",
    component: _58841267,
    name: "product"
  }, {
    path: "/salesOrder",
    component: _f1032800,
    name: "salesOrder"
  }, {
    path: "/setting",
    component: _4c1fce88,
    name: "setting"
  }, {
    path: "/stock",
    component: _a61ac9e4,
    name: "stock"
  }, {
    path: "/vendor",
    component: _161904b4,
    name: "vendor"
  }, {
    path: "/auth/login",
    component: _d5815446,
    name: "auth-login"
  }, {
    path: "/category/create",
    component: _46ee6dbc,
    name: "category-create"
  }, {
    path: "/customer/create",
    component: _51250b7c,
    name: "customer-create"
  }, {
    path: "/customer/edit",
    component: _1dc363d0,
    name: "customer-edit"
  }, {
    path: "/customer/form",
    component: _4afbd4ec,
    name: "customer-form"
  }, {
    path: "/discount/create",
    component: _17034e02,
    name: "discount-create"
  }, {
    path: "/internal/allStock",
    component: _c6cb5f82,
    name: "internal-allStock"
  }, {
    path: "/internal/deliveryCompany",
    component: _c401c516,
    name: "internal-deliveryCompany"
  }, {
    path: "/internal/store",
    component: _01b55bad,
    name: "internal-store"
  }, {
    path: "/liveBroadcast/create",
    component: _07718a1e,
    name: "liveBroadcast-create"
  }, {
    path: "/product/create",
    component: _409af592,
    name: "product-create"
  }, {
    path: "/setting/appearance",
    component: _fbc858c4,
    name: "setting-appearance"
  }, {
    path: "/setting/general",
    component: _32adb7c4,
    name: "setting-general"
  }, {
    path: "/setting/language",
    component: _1beb5112,
    name: "setting-language"
  }, {
    path: "/setting/payment",
    component: _56768e3c,
    name: "setting-payment"
  }, {
    path: "/setting/shipping",
    component: _fe4aff30,
    name: "setting-shipping"
  }, {
    path: "/stock/create",
    component: _19151170,
    name: "stock-create"
  }, {
    path: "/stock/form",
    component: _75e1a038,
    name: "stock-form"
  }, {
    path: "/vendor/create",
    component: _5d9efc50,
    name: "vendor-create"
  }, {
    path: "/vendor/edit",
    component: _baa14e34,
    name: "vendor-edit"
  }, {
    path: "/vendor/form",
    component: _5f6e0aa0,
    name: "vendor-form"
  }, {
    path: "/internal/deliveryCompany/create",
    component: _4998dbe9,
    name: "internal-deliveryCompany-create"
  }, {
    path: "/internal/store/create",
    component: _5caa64b1,
    name: "internal-store-create"
  }, {
    path: "/internal/store/form",
    component: _1584cfb9,
    name: "internal-store-form"
  }, {
    path: "/internal/deliveryCompany/:id",
    component: _3a2ff91d,
    name: "internal-deliveryCompany-id"
  }, {
    path: "/internal/store/:id",
    component: _b75f3556,
    name: "internal-store-id"
  }, {
    path: "/category/:id",
    component: _a50682f8,
    name: "category-id"
  }, {
    path: "/customer/:id",
    component: _689aa164,
    name: "customer-id"
  }, {
    path: "/datetime/:id",
    component: _1aeff001,
    name: "datetime-id"
  }, {
    path: "/discount/:id",
    component: _b918f872,
    name: "discount-id"
  }, {
    path: "/liveBroadcast/:id",
    component: _23914c50,
    name: "liveBroadcast-id"
  }, {
    path: "/product/:id",
    component: _1ea0bc5c,
    name: "product-id"
  }, {
    path: "/salesOrder/:id",
    component: _67b76e68,
    name: "salesOrder-id"
  }, {
    path: "/stock/:id",
    component: _d22fee14,
    name: "stock-id"
  }, {
    path: "/vendor/:id",
    component: _c31e9ce4,
    name: "vendor-id"
  }, {
    path: "/liveBroadcast/:id/controlPanel",
    component: _3637be91,
    name: "liveBroadcast-id-controlPanel"
  }, {
    path: "/",
    component: _b2dfe6f2,
    name: "index"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config.app && config.app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
