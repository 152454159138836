export default {
  range: "Date & Time Range",
  from: "From",
  to: "To",
  specific: "Specific Date",
  addDateRange: "Add more date range",
  dayOfWeek: "Day of Week",
  timeRange: "Time Range every available day",
  addTimeSlot: "Add more time slot",
  chooseLabel: "Choose from Calendar",
  placeholder: "Picker in menu",
  week: {
    mon: "Monday",
    tue: "Tuesday",
    wed: "Wednesday",
    thur: "Thursday",
    fri: "Friday",
    sat: "Saturday",
    sun: "Sunday"
  }
};
