export default {
  cancel: "Cancel",
  save: "Save",
  confirm: "Confirm",
  delete: "Delete",
  next: "Next",
  back: "Back",
  update: "Update",
  add: "Add",
  ok: "OK",
  confirmUpdate: "Confirm Update"
};
