// eslint-disable-next-line no-unused-vars,@typescript-eslint/no-unused-vars
import { AxiosRequestConfig } from "axios";

export default function({ app }, inject) {
  const prefix = "/cms/v1";
  const apiFetch = {
    stores: {
      getStoreDetail(storeID) {
        return app.$api.$get(`${prefix}/stores/get/${storeID}`);
      },
      getStoreList(config = null) {
        if (config) {
          return app.$api.$get(`${prefix}/stores/list`, config);
        } else {
          return app.$api.$get(`${prefix}/stores/list`);
        }
      },
      getAllStoreList() {
        return app.$api.$get(`${prefix}/stores/list-all`);
      },
      updateStoreDetail(storeID, config = null) {
        return app.$api.$patch(`${prefix}/stores/update/${storeID}`, config);
      },
      createNewStore(config = null) {
        return app.$api.$post(`${prefix}/stores/create`, config);
      }
    },
    /**
     * @param {AxiosRequestConfig|null} config
     * @returns {Promise<any>}
     */
    getLocaleList(config = null) {
      if (config) {
        return app.$api.$get(`${prefix}/locales/list`, config);
      } else {
        return app.$api.$get(`${prefix}/locales/list`);
      }
    },
    getProductList(config = null) {
      if (config) {
        return app.$api.$get("/cms/v1/products/list", config);
      } else {
        return app.$api.$get("/cms/v1/products/list");
      }
    },
    /**
     * @param {AxiosRequestConfig|null} config
     * @returns {Promise<any>}
     */
    getCurrenciesList(config = null) {
      if (config) {
        return app.$api.$get(`${prefix}/currencies/list`, config);
      } else {
        return app.$api.$get(`${prefix}/currencies/list`);
      }
    },
    getUserDetail() {
      return app.$api.$get(`${prefix}/user`);
    },
    logout() {
      return app.$api.post("/api/logout");
    },
    services: {
      getServiceList() {
        return app.$api.$get("/cms/v1/services/list");
      }
    },
    paymentMethods: {
      getPaymentMethodList() {
        return app.$api.$get("/cms/v1/payment-methods/list");
      }
    },
    deliveryCompany: {
      list(config = null) {
        return app.$api.$get(`${prefix}/delivery-company/list`, config);
      },
      get(id) {
        return app.$api.$get(`${prefix}/delivery-company/get/${id}`);
      },
      create(config = null) {
        return app.$api.$post(`${prefix}/delivery-company/create`, config);
      },
      edit(id, config) {
        return app.$api.$patch(
          `${prefix}/delivery-company/update/${id}`,
          config
        );
      }
    },
    discount: {
      getDiscountList(config = null) {
        return app.$api.$get(`${prefix}/discounts/list`, config);
      }
    },
    fulfilments: {
      /**
       * @param {AxiosRequestConfig|null} config
       * @returns {Promise<any>}
       */
      listFulfilmentWithDetail(config = null) {
        if (config) {
          return app.$api.$get(`${prefix}/fulfilments/list-detail`, config);
        } else {
          return app.$api.$get(`${prefix}/fulfilments/list-detail`);
        }
      },
      cancelFulfilment(fulfilmentID) {
        return app.$api.patch(`${prefix}/fulfilments/cancel/${fulfilmentID}`);
      },
      deliveringFulfilment(fulfilmentID) {
        return app.$api.patch(
          `${prefix}/fulfilments/delivering/${fulfilmentID}`
        );
      },
      deliveredFulfilment(fulfilmentID) {
        return app.$api.patch(
          `${prefix}/fulfilments/delivered/${fulfilmentID}`
        );
      },
      updateFulfilmentLineItemInfo(lineItemID, config = null) {
        return app.$api.post(
          `${prefix}/fulfilments/line-items/${lineItemID}`,
          config
        );
      },
      getFulfilmentLineItemInfo(lineItemID) {
        return app.$api.$get(`${prefix}/fulfilments/line-items/${lineItemID}`);
      },
      updateTracking(fulfilmentID, config = null) {
        return app.$api.$patch(
          `${prefix}/fulfilments/tracking/${fulfilmentID}`,
          config
        );
      }
    },
    getSalesOrderDetail(id) {
      return app.$api.$get(`${prefix}/sales-orders/get/${id}`);
    },
    /**
     * @param {AxiosRequestConfig|null} config
     * @returns {Promise<any>}
     */
    getDashboardSalesTotal(config = null) {
      if (config) {
        return app.$api.$get(`${prefix}/dashboard/sales-total`, config);
      } else {
        return app.$api.$get(`${prefix}/dashboard/sales-total`);
      }
    },
    /**
     * @param {AxiosRequestConfig|null} config
     * @returns {Promise<any>}
     */
    getDashboardTopSalesProduct(config = null) {
      if (config) {
        return app.$api.$get(`${prefix}/dashboard/top-sales-product`, config);
      } else {
        return app.$api.$get(`${prefix}/dashboard/top-sales-product`);
      }
    },
    /**
     * @param {AxiosRequestConfig|null} config
     * @returns {Promise<any>}
     */
    getDashboardOrderSalesAverage(config = null) {
      if (config) {
        return app.$api.$get(`${prefix}/dashboard/order-sales-avg`, config);
      } else {
        return app.$api.$get(`${prefix}/dashboard/order-sales-avg`);
      }
    },
    /**
     * @param {AxiosRequestConfig|null} config
     * @returns {Promise<any>}
     */
    getDashboardOrderCount(config = null) {
      if (config) {
        return app.$api.$get(`${prefix}/dashboard/order-count`, config);
      } else {
        return app.$api.$get(`${prefix}/dashboard/order-count`);
      }
    }
  };
  inject("apiFetch", apiFetch);
}
