export default {
  moduleName: "Products",
  searchLabel: "Product Name / SKU / Category",
  createButton: "Add Product",
  tableHeader: {
    product_name: "Product Name",
    sku: "SKU",
    original_price: "Original Price",
    discounted_price: "Discounted Price",
    inventory: "Inventory",
    category: "Category",
    actions: "Actions"
  },
  form: {
    tab: {
      overview: "Overview",
      option: "Product Options",
      stock: "Stock Management"
    },
    overview: {
      title: "Product Title",
      description: "Description",
      category: "Category",
      type: "Product Type",
      region: "Region",
      available: "Available for selling",
      price: "Price",
      images: "Images",
      imageHints: "Sorting images by drag and drop",
      noImage: "There are no image uploaded yet.",
      addImages: "Add Images",
      vendor: "Vendor",
      selectVendor: "Select Vendor",
      selectCategory: "Select Categories",
      inventory: {
        title: "Inventory",
        sku: "SKU",
        stockQty: "Stock Level",
        vendor: "Vendor",
        fulfillment: "On-demand Fulfillment",
        ps: "Choose this only if the order is fulfilled by 3rd party directly",
        issuing: {
          title: "E-Vouchers issuing",
          no: "No voucher",
          qty: "Issue voucher per qty",
          line: "Issue voucher per line item"
        }
      },
      hotRecommend: {
        title: "Display this products on landing page",
        sortOrder: "Sorting Order"
      },
      additional: {
        title: "Additional Information requirement",
        require:
          "Require additional information input for each qty of purchase",
        list: {
          name: "Name",
          local: "Mobile Number (Local)",
          oversea: "Mobile Number (Oversea)",
          passport: "Passport No.",
          sex: "Sex",
          address: "Address",
          remarks: "Remarks"
        }
      },
      blog: "Related Blog",
      selectBlog: "Select Blog",
      blogModal: {
        title: "Select Product",
        search: "Blog Name",
        selected: "{0} Items selected"
      }
    },
    options: {
      withChild:
        "This product has multiple options, like different sizes or colors",
      title: "Product options",
      subTitle:
        "The first set of options will be used for quantity selection when customers add your products to cart.",
      categories: "Categories",
      values: "Values",
      valuesPlacehoder: "Press Enter or comma to separate options",
      edit: "Edit Options",
      variant: "Variant Management",
      enabled: "Enabled",
      childProductName: "Product Name",
      editOption: {
        title: "Edit product options",
        delete: "Delete",
        qtySelect: "For qty selection",
        addOption: "Add Product Option",
        variantGenerate: "Variant generate",
        cannotDelete: "Created Variant cannot be deleted",
        optionMissing: "Product options cannot be blank",
        emptyOption: "Please add at least one product option",
        variants: {
          enabled: "Enabled",
          new: "New Variant?",
          delete: "Delete",
          keep: "Keep this option"
        },
        newVariant: {
          title: "New Variant - SKU Linkage",
          subTitle:
            "When a variant of the product is sold, quantity will be deducted from the SKU linked to the variant.",
          auto: "Automatically create individual SKU for new variant",
          link: "Link SKU manually",
          name: "Variant Name",
          skuName: "SKU Name",
          sku: "SKU #",
          createNewSKU: {
            title: "Create New SKU?",
            create: "Create",
            useExist: "Use Existing SKU"
          },
          changeLinkage: {
            label: "Change Linkage",
            title: "Select SKU",
            add: "Add more SKU"
          }
        }
      },
      salesSetting: {
        title: "Price and Sales setting",
        pricing: {
          title: "Pricing Model",
          subTitle:
            "Product with regular pricing model could change to other pricing model <br/> Once non-regular pricing Model is set, no pricing model change is allowed.",
          daily: {
            label: "Daily Rental",
            ps:
              "Total price before discount / tax of an item will be: <br/>Sum of each day's rental price * quantity<br/>User will need to select start date & end date for the purchase",
            shortest: "Shortest duration of rental allowed",
            longest: "Longest duration of rental allowed"
          },
          singleDate: {
            label: "One-off price, single date selection is needed",
            ps:
              "Selecting a date is required for customer to purchase this product<br/>Suitable for ticketing <br/>Total price before discount / tax will be:<br/>Unit Price * Quantity<br/>"
          },
          singleDateTime: {
            label: "One-off price, single date and time selection is needed",
            ps:
              "Selecting a date and a timeslot are required for customer to purchase this product<br/>Suitable for ticketing <br/>Total price before discount / tax will be:<br/>Unit Price * Quantity"
          },
          noDate: {
            label: "Regular",
            ps:
              "Total price before discount / tax will be:<br/>Unit Price * Quantity"
          },
          availableUntil: "Available until",
          daysBefore: "days before service start date"
        },
        priceSetting: {
          title: "Price Setting",
          date: "Price at :date",
          dateTime: "Price and time at :date",
          dateTimePs: "Date without time slot are not available for sale",
          available: "Available for sale",
          startTime: "Start Time",
          endTime: "End Time",
          unitPriceB4Discount: "Unit Price before discount",
          actualUnitPrice: "Actual Unit Price",
          unitCost: "Unit Cost",
          skuQty: "SKU qty",
          setQty: "Set qty to",
          add: "Add more time slot"
        },
        bulkUpdatePrice: {
          title: "Bulk Update Price",
          unitPriceB4Discount: "Unit Price before discount",
          actualUnitPrice: "Actual Unit Price",
          unitCost: "Unit Cost"
        },
        bulkUpdateSetting: {
          title: "Bulk Update 'Available for sale' setting",
          available: "Available for sale",
          notAvailable: "Not available for sale"
        }
      },
      availability: {
        title: "Availability",
        unlimited: "Unlimited availability",
        ps:
          "This option is available for sale anytime if it's not at blackout period, and it is not out of stock",
        addNewAvailablePeriod: "Add new available period group"
      },
      blackout: {
        title: "Blackout",
        addNewBlackoutPeriod: "Add new blackout period group"
      },
      instruct: {
        title: "Redeem instruct"
      },
      bulkPopupCommon: {
        applyToProducts: "Apply to products"
      },
      bulkPriceUpdate: {
        title: "Bulk Price Update",
        permanent: "Permanent",
        unitPriceBeforeDiscount: "Unit Price before discount",
        actualUnitPrice: "Actual Unit Price",
        unitCost: "Unit Cost"
      },
      bulkAddBlackout: {
        title: "Bulk Add Blackout"
      },
      bulkAddAvailability: {
        title: "Bulk Add Availability",
        period: "Period"
      }
    },
    stock: {
      classify: "Classify inventory by date",
      slot: "Manage stock by date and time slot",
      ps: "The item below will have a separated stock each day",
      variant: "Variant Management",
      manage: "Stock Manage",
      log: "Stock Log",
      sold: "Sold Out",
      logTableHeader: {
        operate: "Operate Date & Time",
        source: "Source",
        uuid: "#",
        usage: "In/Out",
        qty: "Qty",
        balance: "Balance",
        operator: "Operator"
      },
      addQty: "Add",
      deductQty: "Deduct",
      setQty: "Set To",
      qtyRemain: "Qty Remain: :qty"
    }
  }
};
