export default ({ app }) => {
  const getSessionStatus = async function() {
    console.log("check session availability");
    try {
      const result = await app.$api.$get("/api/session-status");
      if (result.status === false) {
        app.store.commit("setLoginStatus", false);
      } else {
        // if session still valid, setup timer to prevent idle for too long cause session timeout
        setUpSessionCheckTimer();
      }
    } catch (err) {
      console.log(err);
      app.router.push({ name: "auth-login" });
    }
  };

  // function for calling the server to ensure that session is still valid
  const setUpSessionCheckTimer = function() {
    const oldSessionCheckTimeoutID = app.store.getters.getSessionCheckTimeoutID;
    // set a delayed call to check if the session is timeout, to prevent user idle for too long and the session is timeout
    // reset the last timeout check
    if (oldSessionCheckTimeoutID !== null) {
      window.clearTimeout(oldSessionCheckTimeoutID);
    }

    const newSessionCheckTimeoutID = window.setTimeout(function() {
      getSessionStatus();
    }, 600000); // check session status if idle for over 10 minutes

    app.store.commit("setSessionTimeoutID", newSessionCheckTimeoutID);
  };

  app.router.afterEach(async (to, from) => {
    // check user is on login page or not
    if (to.name !== "auth-login" && from.name !== "auth-login") {
      // if user is not from login page (just logged in, no need to check session status to prevent race condition
      // or not going to login page, check session status
      if (app.store.getters.getLoginStatus === true) {
        await getSessionStatus();
        app.router.push({ name: to.name });
      } else {
        app.router.push({ name: "auth-login" });
      }
    }
  });
};
