export default {
  moduleName: "訂單",
  filteringButton: {
    all: "全部",
    draft: "草稿",
    unfulfilled: "未完成/部分完成",
    completed: "已完成"
  },
  searchLabel: "訂單號/客戶名稱",
  createButton: "創建訂單",
  tableHeader: {
    contact_first_name: "顧客名",
    contact_name: "顧客姓名",
    contact_last_name: "顧客姓",
    currency_code: "貨幣",
    grand_total: "總額",
    total_amount_paid: "付款額",
    order_date_time: "建立日期",
    paid: "訂單已支付？",
    fulfilled: "出貨狀態",
    actions: "行動"
  },
  form: {
    sidebar: {
      searchCustomer: "搜索客戶...",
      contact: "聯繫",
      shipTo: "運送到",
      edit: "編輯",
      contactPopup: {
        title: "更改聯繫資料",
        firstName: "名字",
        lastName: "姓",
        phone: "電話號碼",
        email: "電郵"
      },
      shippingPopup: {
        title: "更改聯繫資料",
        firstName: "名字",
        lastName: "姓",
        phone: "電話號碼",
        address1: "地址 1",
        address2: "地址 2",
        address3: "地址 3",
        city: "城市",
        country: "國家"
      }
    },
    tabs: {
      order: "訂單",
      fulfillment: "出貨"
    },
    itemTableHeader: {
      item: "項目",
      discount: "折扣",
      unitPrice: "單價",
      quantity: "數量",
      fulfilled: "已送貨數量",
      qtyWithoutFulfilmentRecord: "未出貨數量",
      total: "總額"
    },
    notes: "註釋",
    addNote: "添加註釋...",
    actionButtonPanel: {
      fulfillOrder: "創建出貨單",
      markAsPaid: "標記為已付款",
      refund: "退款",
      exportAsPdf: "導出為PDF",
      cancelOrder: "取消訂單",
      sendOrder: "發送訂單給客戶"
    },
    amountPanel: {
      discount: "折扣",
      subTotal: "小計",
      shipping: "運輸",
      total: "總額",
      paidBy: "支付方式",
      paymentMethod: {
        weChat: "WeChat"
      },
      refund: "退款"
    },
    formFulfillment: {
      trackingInfo: "追踪信息",
      addTrackingInfo: "添加追踪信息",
      addTrackingNumber: "添加提單號",
      trackingNumber: "提單號",
      attachVoucher: "附加優惠",
      editVoucher: "查看/編輯優惠",
      confirmFulfillment: "確認出貨",
      cancelFulfillment: "取消出貨",
      delivering: "出貨中",
      delivered: "已送達",
      deliveryCompany: "速遞公司",
      cancelDialogMessage: "是否要取消此出貨記錄？",
      deliveringDialogMessage: "確認將此出貨記錄狀態更新為「出貨中」？",
      deliveredDialogMessage: "確認將此出貨記錄狀態更新為「已送達」？"
    },
    popup: {
      productName: "產品名稱..."
    },
    fulfillOrderPopup: {
      title: "出貨",
      quantity: "數量",
      of: "的",
      confirmFulfillment: "確認出貨"
    },
    refundPopup: {
      title: "退款",
      refundReason: "退款原因（僅限內部）",
      restoreItems: "恢復退款項目",
      refund: "退款"
    },
    voucherPopup: {
      title: "附加優惠",
      voucherNumber: "優惠號碼",
      attach: "附上"
    }
  }
};
