import { createHelpers } from "vuex-map-fields";

const { getUserField, updateUserField } = createHelpers({
  getterType: "getUserField",
  mutationType: "updateUserField"
});

function initialState() {
  return {
    user: {}
  };
}

export const state = () => initialState();

export const mutations = {
  updateUserField,
  updateUser(state, payload) {
    state.user = _.cloneDeep(payload);
  }
};

export const getters = {
  getUserField,
  getUserType(state) {
    return _.get(state.user, "type", 0);
  }
};
