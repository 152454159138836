export default {
  "--primary-color": "Primary Color",
  "--secondary-color": "Secondary Color",
  "--text-color": "Text Color",
  "--bg-color": "Background Color",
  "--highlight-text-color": "Highlight Text Color",
  "--dim-text-color": "Dim Text Color",
  "--success-text-color": "Success Text Color",
  "--success-bg-color": "Success Background Color",
  "--danger-text-color": "Danger Text Color",
  "--danger-bg-color": "Danger Background Color",
  "--overlay-color": "Overlay Color"
};
