<script>
// mixin for checking user permission
export default {
  computed: {
    userType() {
      return this.$store.getters["user/getUserType"];
    },
    /**
     * check if user is a super admin
     * @returns {boolean}
     */
    userIsSuperAdmin() {
      return this.userType >= 99;
    }
  }
};
</script>
