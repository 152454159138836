export default {
  moduleName: "顧客和用戶",
  searchLabel: "顧客姓名 / 電話號碼",
  createButton: "新增顧客",
  tableHeader: {
    name: "顧客 / 用戶姓名",
    amount: "消費金額",
    order: "訂單數",
    actions: "行動"
  },
  form: {
    tab: {
      overview: "總覽",
      order: "相關訂單"
    },
    firstName: "名",
    lastName: "姓",
    email: "電郵",
    emailCannotEdit: "帳號創建後不能修改電子郵箱",
    phone: "電話號碼",
    active: "啟用",
    accept: "接受營銷",
    shipping: {
      title: "收件地址",
      contactFirstName: "聯繫人名",
      contactLastName: "聯繫人姓",
      receiverFirstName: "收件人名",
      receiverLastName: "收件人姓",
      address1: "地址1",
      address2: "地址2",
      city: "城市",
      region: "省/地區",
      country: "國家"
    },
    relatedOrderTableHeader: {
      uuid: "#",
      name: "顧客姓名",
      currency: "貨幣",
      total: "總額",
      balance: "結餘",
      created_at: "建立日期",
      pay_status: "支付狀態",
      ful_status: "出貨狀態"
    }
  }
};
