export default {
  accepted: "該 :attribute 必須被接受",
  active_url: "該: attribute 不是有效的URL",
  after: "該 :attribute 必須是：date 以後的日期。",
  after_or_equal: "該 :attribute 必須是在 :date 或 以後的日期",
  alpha: "該 :attribute 只能包含字母",
  alpha_dash: "該 :attribute 只能包含字母，數字，破折號和下劃線。",
  alpha_num: "該 :attribute 只能包含字母和數字。",
  array: "該 :attribute 必須是一個數組。",
  before: "該 :attribute 必須是 :date 之前的日期。",
  before_or_equal: "該 :attribute 必須是在 :date 或 以後的日期",
  between: {
    numeric: "該 :attribute 必須在 :min 和 :max 之間",
    file: "該 :attribute 必須在：:min 和 :max 千字節之間.",
    string: "該 :attribute 必須在 :min 和 :max 字之間.",
    array: "該 :attribute 必須在 :min 和 :max 項目之間."
  },
  boolean: "該 :attribute 字段必須為true或false",
  confirmed: "該 :attribute 確認不匹配",
  date: "該 :attribute 不是有效日期.",
  date_equals: "該 :attribute 日期必須等於 :date.",
  date_format: "該 :attribute 與:format 格式不符.",
  different: "該 :attribute 和 :other 必須不同",
  digits: "該 :attribute 必須 :digits 數字",
  digits_between: "該 :attribute 必須在 :min 和 :max 數字.",
  dimensions: "該 :attribute 圖片尺寸無效.",
  distinct: "該 :attribute 字段具有重複值",
  email: "該 :attribute 必須是一個有效的電郵",
  ends_with: "該 :attribute 必須以以下任一結尾: :values",
  exists: "該選擇 :attribute 是無效的.",
  file: "該 :attribute 必須是一個文件。",
  filled: "該 :attribute 字段必須有一個值。",
  gt: {
    numeric: "該 :attribute 必須大於 :value.",
    file: "該 :attribute 必須大於 :value 千字節.",
    string: "該 :attribute 必須大於 :value 字.",
    array: "該 :attribute 必須超過 :value 項目."
  },
  gte: {
    numeric: "該 :attribute 必須大於或等於 :value.",
    file: "該 :attribute 必須大於或等於 :value 千字節.",
    string: "該 :attribute 必須大於或等於 :value 字.",
    array: "該 :attribute 必須具有 :value 項目或更多."
  },
  image: "該 :attribute 必須是圖像。",
  in: "該選擇 :attribute 是無效的",
  in_array: "該 :attribute 字段不存在於 :other.",
  integer: "該 :attribute 必須是整數.",
  ip: "該 :attribute 必須是有效的IP地址。",
  ipv4: "該 :attribute 必須是有效的IPv4地址.",
  ipv6: "該 :attribute 必須是有效的IPv6地址",
  json: "該 :attribute 必須是有效的JSON字符串。",
  lt: {
    numeric: "該 :attribute 必須小於 :value.",
    file: "該 :attribute 必須小於 :value 千字節.",
    string: "該 :attribute 必須小於 :value 字.",
    array: "該 :attribute 必須少於 :value 項目."
  },
  lte: {
    numeric: "該 :attribute 必須小於或等於 :value ",
    file: "該 :attribute 必須小於或等於 :value 千字節.",
    string: "該 :attribute 必須小於或等於 :value 字.",
    array: "該 :attribute 不得超過 :value 項目."
  },
  max: {
    numeric: "該 :attribute 不得大於 :max.",
    file: "該 :attribute 不得大於 :max 千字節.",
    string: "該 :attribute 不得大於 :max 字.",
    array: "該 :attribute 不得超過 :max 項目."
  },
  mimes: "該 :attribute 必須是: :values 類型的文件",
  mimetypes: "該 :attribute 必須是: :values 類型的文件",
  min: {
    numeric: "該 :attribute 必須至少 :min.",
    file: "該 :attribute 必須至少 :min 千字節.",
    string: "該 :attribute 必須至少 :min 字.",
    array: "該 :attribute 必須至少有 :min 項目."
  },
  not_in: "該選擇 :attribute 是無效的。",
  not_regex: "該 :attribute 格式無效。",
  numeric: "該 :attribute 必須是數字。",
  present: "該 :attribute 字段必須存在。",
  regex: "該 :attribute 格式無效。",
  required: "該 :attribute 必填字段。",
  required_if: "該 :attribute 必填當 :other 是 :value.",
  required_unless: "該 :attribute 必填除非 :other 是在 :values.",
  required_with: "該 :attribute 必填當 :values 存在。",
  required_with_all: "該 :attribute 必填當 :values 存在。.",
  required_without: "該 :attribute 必填當 :values 不存在。",
  required_without_all: "該 :attribute 必填當 沒有 :values 存在。",
  same: "該 :attribute 和 :other 必須匹配。",
  size: {
    numeric: "該 :attribute 必須是 :size.",
    file: "該 :attribute 必須是 :size 千字節.",
    string: "該 :attribute 必須是 :size 字.",
    array: "該 :attribute 必須包含 :size 項目."
  },
  starts_with: "該 :attribute 必須以下列其中一項開頭: :values",
  string: "該 :attribute 必須是一個字符串.",
  timezone: "該 :attribute 必須是有效區域.",
  unique: "該 :attribute 已經被採取。",
  uploaded: "該 :attribute 上傳失敗。",
  url: "該 :attribute 格式無效。",
  uuid: "該 :attribute 必須是有效的UUID。",
  custom: {
    attribute_name: {
      rule_name: "自定義消息"
    }
  },
  attributes: {}
};
