export default {
  moduleName: "分类",
  searchLabel: "分类名称",
  createButton: "创建类别",
  tableHeader: {
    name: "分类名称",
    actions: "行动"
  },
  name: "分类名称"
};
