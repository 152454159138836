import lang from "@/lang/lang";
import numberFormats from "./numberFormats";

export default {
  locale: "en",
  locales: [
    {
      code: "en",
      name: "English"
    },
    {
      code: "zh_HK",
      name: "繁體中文"
    },
    {
      code: "zh_CN",
      name: "简体中文"
    }
  ],
  fallbackLocale: "zh_HK",
  messages: {
    en: lang.en,
    zh_HK: lang.zhHant,
    zh_CN: lang.zhHans
  },
  numberFormats,
  silentTranslationWarn: true
};
