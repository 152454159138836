export default {
  moduleName: "獎勵和折扣",
  searchLabel: "折扣名稱",
  createButton: "創建獎勵/折扣",
  tableHeader: {
    name: "獎勵/折扣名稱",
    performance: "表現",
    status: "狀態",
    actions: "行動"
  },
  form: {
    tab: {
      overview: "總覽",
      outcome: "結果"
    },
    title: "標題",
    description: "描述",
    enable: "啟用",
    trigger: {
      title: "觸發條件",
      orderTotal: "訂單總數",
      specificProduct: "具體產品",
      totalQtyRequirement: "總數量要求",
      specificCustomer: "具體客戶",
      specificDay: "星期幾",
      specificDayRange: "具體日期範圍",
      addDateRange: "添加日期範圍",
      couponCode: "折扣代碼",
      from: "由",
      to: "到"
    },
    stackability: {
      title: "可堆疊性",
      all: "可全部堆疊",
      discount: "可堆疊至特定折扣",
      nonAll: "不可全部堆疊",
      nonDiscount: "不可堆疊至特定折扣"
    },
    outcome: {
      option: "結果選項",
      type: "價值類型",
      value: "價值",
      includedQty: "包括數量",
      issueQty: "發行數量",
      qty: "數量",
      override: "替代商品價格",
      addOutcome: "添加新結果"
    }
  }
};
