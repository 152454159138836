export default {
  action: "行動",
  back: "返回",
  lastStep: "上一步",
  nextStep: "下一步",
  confirm_delete: "確認刪除?",
  confirm: "確認",
  cancel: "取消",
  create: "新增",
  delete: "刪除",
  edit: "編輯",
  export: "輸出",
  keyword: "關鍵詞",
  list: "清單",
  manage: "管理",
  no_record: "無記錄",
  save: "儲存",
  search: "按關鍵詞搜索",
  searchByChannel: "按頻道搜索",
  view: "檢視",
  more: "更多"
};
